import isDataSet from "./isDataSet";
const ParseJson = (data = null, typeExpected = {}) => {
  if (isDataSet(data)) {
    try {
      if (data.constructor === "".constructor) return JSON.parse(data);
      else return data;
    } catch (err) {
      return typeExpected;
    }
  }
  return typeExpected;
};

export default ParseJson;
