import tickIcon from "assets/XMC-UI-1-ASSETS/tick_blue.png";
import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Modal, ModalBody, Row } from "reactstrap";

const buttonStyle = {
  borderRadius: "2px",
  width: "max-content",
};

const UpdatedPasswordModal = ({ modalOpen, setModalOpen }) => {
  return (
    <Modal
      isOpen={modalOpen}
      style={{ maxWidth: "550px" }}
      size="m"
      // toggle={() => setRedirectTo("/loyalty")}
      centered
      className="save-modal"
    >
      <ModalBody>
        <div style={{ margin: "20px" }}>
          <Row>
            <Col style={{ textAlign: "center", margin: "25px" }}>
              <img
                src={tickIcon}
                alt="Scheduled!"
                className="mr-3 ml-3"
                width="85"
                height="85"
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col
              style={{
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
                color: "black",
                marginBottom: "10px",
              }}
            >
              Your Password has been updated
            </Col>
          </Row>
          <br></br>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(UpdatedPasswordModal);
