import React, { useState, useEffect } from "react";
import Loader from "react-loaders";

const AnalyticsReportKPIFabIndia = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false); // Since we're directly using the iframe, we can stop loading immediately.
  }, []);

  return (
    <div
      className="mt-4 mb-1"
      style={{
        height: "calc(100vh - 120px)",
      }}
      id="embeddingContainer"
    >
      {loading && (
        <div className="text-center">
          <Loader type={"ball-pulse"} />
          <h6>Loading Dashboard</h6>
        </div>
      )}
      {!loading && (
        <iframe
          src="https://metabase.xeno.in/public/dashboard/8ef1688b-e0c3-4b3e-b8cb-1d7779fe405e"
          frameBorder="0"
          width="100%"
          height="100%"
          allowTransparency
          style={{ border: "none" }}
        ></iframe>
      )}
    </div>
  );
};

export default AnalyticsReportKPIFabIndia;
