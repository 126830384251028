import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageLoader from "../Components/PageLoader";
import { merchant } from "../utils/functions/getStorageObject";
import isDataSet from "../utils/functions/isDataSet";
import isObjectEmpty from "../utils/functions/IsObjectEmpty";
import { isDemoAccount } from "../utils/functions/MerchantUtils";
import { isStaffUser } from "../utils/functions/UserUtils";
import AccessLevelRoutes, { isRouteAccessible } from "./AccessLevelRoutes";
import LoggedInRoutes from "./LoggedInRoutes";
import LoggedOutRoutes from "./LoggedOutRoutes";
import { XENO_ROUTES, XENO_ROUTES_2 } from "./RoutesUtil";
import { BusinessDashboard } from "Containers/AWSQuickSight/BusinessDashboard";
import CustomerDetailsDashboard from "Containers/AWSQuickSight/CustomerDetailsDashboard";
import OrderDetailsDashboard from "../Containers/AWSQuickSight/OrderDetailsDashboard";
import CampaignHitRate from "Containers/CampaignDashboard/CampaignHitRate";
import MyDashboard from "../Containers/CampaignDashboard/MyDashboard";
import LoyaltyDetailsDashboard from "../Containers/AWSQuickSight/LoyaltyDashboard";
import CampaignDashboardV2 from "../Containers/AWSQuickSight/CampaignDashboardV2";
import OtpDashboard from "../Containers/AWSQuickSight/OtpDashboard";
import FabIndiaDashboard from "../Containers/AWSQuickSight/FabIndiaDashboard";
import ManagementKPIFabIndia from "Containers/AWSQuickSight/AnalyticsReportKPIFabIndia";
import AnalyticsReportKPIFabIndia from "Containers/AWSQuickSight/ManagementKPIFabIndia";
import PointsExpiryFabIndia from "Containers/AWSQuickSight/PointsExpiryFabIndia";
import SocialFraudDetectionDashboard from "Containers/AWSQuickSight/SocialFraudReportDashBoard";
import BeingHumanCouponRedemptionDashboard from "Containers/AWSQuickSight/BeingHumanCouponRedemptionDashboard";
import WWIanalysisDashboard from "Containers/AWSQuickSight/WWIanalysisDashboard";
import TieroverviewDashboard from "Containers/AWSQuickSight/TieroverviewDashboard";

const CustomerProfile = lazy(() => import("../Containers/360customer_profile"));
const login = lazy(() => import("../Containers/login"));
const topol = lazy(() =>
  import("../Containers/CampaignCreation/Creation/Personalized/Topol")
);
const PersonaInsight = lazy(() => import("../Containers/PersonaInsight"));
const CampaignDashboard = lazy(() => import("../Containers/CampaignDashboard"));
const PreCampaignAnalytics = lazy(() =>
  import("../Containers/PreCampaignAnalytics")
);
const Ginesys = lazy(() => import("../Containers/Ginesys"));
const ClaimCoupon = lazy(() => import("../Containers/Ginesys/claimCoupon"));
const Campaign = lazy(() => import("../Containers/Campaign"));
const CampaignCreation = lazy(() => import("../Containers/CampaignCreation"));
const Reporting = lazy(() => import("../Containers/Reporting"));
const PersonaConfig = lazy(() => import("../Containers/PersonaConfig"));
const Dashboard = lazy(() => import("../Containers/Dashboard"));
const MerchantSetup = lazy(() => import("../Containers/MerchantSetup"));
const Utilities = lazy(() => import("../Containers/Utilities"));
const JourneyBuilder = lazy(() => import("../Containers/JourneyBuilder"));
const MerchantLoyalty = lazy(() => import("../Containers/MerchantLoyalty"));
const CustomerLoyalty = lazy(() => import("../Containers/CustomerLoyalty"));
const Segmentation = lazy(() => import("../Containers/Segmentation"));
const OfferManagement = lazy(() => import("../Containers/OfferManagement"));
const Shopify = lazy(() => import("../Containers/Shopify"));
const demo = lazy(() => import("../Components/Demo"));
const SqlPredictor = lazy(() => import("../Containers/SqlPredictor"));
const MagentoIntegration = lazy(() =>
  import("../Components/VendorIntegrationsCard/MagentoIntegration")
);
const PosistIntegration = lazy(() =>
  import("../Components/VendorIntegrationsCard/PosistIntegration")
);
const BuisnessReports = lazy(() => import("../Containers/BuisnessReports"));
const VendorSetUp = lazy(() =>
  import("../Containers/MerchantSetup/VendorConfiguration/VendorSetUp")
);

const TestComponent = lazy(() =>
  import(
    "../Containers/CampaignCreation/Creation/Personalized/Utilities/Whatsapp/WhatsappMessageInput"
  )
);

const HomeRoute = () => {
  if (isDemoAccount()) {
    return <Redirect to={XENO_ROUTES_2.DASHBOARD} />;
  } else if (isStaffUser()) {
    return <Redirect to={XENO_ROUTES_2.SEGMENTATION} />;
  } else {
    const route = findFirstAvailableRoute();
    if (isDataSet(route)) {
      return <Redirect to={route.PAGE_URL} />;
    } else {
      if (isDataSet(merchant())) {
        alert(
          "You don't have access to the app.Please check your permissions and login again"
        );
        localStorage.removeItem("user");
        localStorage.removeItem("merchant");
        localStorage.removeItem("userDetails");
        sessionStorage.removeItem("modalOpenChangePassword");
      }
      return <Redirect to={`/login`} />;
    }
  }
};

const findFirstAvailableRoute = () => {
  let route = null;
  if (!isObjectEmpty(XENO_ROUTES)) {
    for (let key in XENO_ROUTES) {
      if (
        !isObjectEmpty(XENO_ROUTES[key]) &&
        isRouteAccessible(XENO_ROUTES[key])
      ) {
        route = XENO_ROUTES[key];
        break;
      }
    }
  }
  return route;
};

const IndexRoutes = ({ match }) => {
  return (
    <Fragment>
      <Suspense
        fallback={
          <PageLoader
            type="ball-spin-fade-loader"
            text="Loading your profile"
          />
        }
      >
        <AccessLevelRoutes
          path={XENO_ROUTES.SEARCH}
          component={CustomerProfile}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.PERSONA_INSIGHTS}
          component={PersonaInsight}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.CAMPAIGNDASHBOARD}
          component={CampaignDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.CAMPAIGNDASHBOARDV2}
            component={CampaignDashboardV2}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.BUSINESSDASHBOARD}
          component={BusinessDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.MYDASHBOARD}
            component={MyDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.FABINDIADASHBOARD}
            component={FabIndiaDashboard}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.SOCIALFRAUDDETECTIONDASHBOARD}
          component={SocialFraudDetectionDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.MANAGEMENTKPIREPORT}
            component={ManagementKPIFabIndia}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.ANALYTICKPIREPORT}
            component={AnalyticsReportKPIFabIndia}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.POINTSEXPIRYREPORT}
            component={PointsExpiryFabIndia}
          />

        <AccessLevelRoutes
            path={XENO_ROUTES.WWIANALYSISDASHBOARD}
            component={WWIanalysisDashboard}
        />

        <AccessLevelRoutes
            path={XENO_ROUTES.TIEROVERVIEWDASHBOARD}
            component={TieroverviewDashboard}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.BEINGHUMANCOUPONREDEMPTIONDASHBOARD}
          component={BeingHumanCouponRedemptionDashboard}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.HITRATEDASHBOARD}
          component={CampaignHitRate}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.CUSTOMERDETAILSDASHBOARD}
            component={CustomerDetailsDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.ORDERDETAILSDASHBOARD}
            component={OrderDetailsDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.LOYALTYDASHBOARD}
            component={LoyaltyDetailsDashboard}
        />
        <AccessLevelRoutes
            path={XENO_ROUTES.OTPDASHBOARD}
            component={OtpDashboard}
        />
        <LoggedOutRoutes path={`/login`} component={login} />
        <LoggedInRoutes path={`/topol`} component={topol} />
        <AccessLevelRoutes path={XENO_ROUTES.CAMPAIGN} component={Campaign} />
        <AccessLevelRoutes
          path={XENO_ROUTES.PERSONALIZED}
          component={CampaignCreation}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.PERSONA_CONFIG}
          component={PersonaConfig}
        />
        <AccessLevelRoutes path={XENO_ROUTES.DASHBOARD} component={Dashboard} />
        <AccessLevelRoutes path={XENO_ROUTES.SETUP} component={MerchantSetup} />
        <AccessLevelRoutes path={XENO_ROUTES.UTILITIES} component={Utilities} />
        <AccessLevelRoutes
          path={XENO_ROUTES.LOYALTY}
          component={MerchantLoyalty}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.JOURNEY}
          component={JourneyBuilder}
        />
        <AccessLevelRoutes
          path={XENO_ROUTES.OFFERS}
          component={OfferManagement}
        />

        <AccessLevelRoutes
          path={XENO_ROUTES.SEGMENTATION}
          component={Segmentation}
        />

        <AccessLevelRoutes
          path={XENO_ROUTES.BUSINESSREPORT}
          component={BuisnessReports}
        />

        <LoggedInRoutes path={`/demo`} component={demo} />
        {/* these needed to be migrated */}
        <LoggedInRoutes
          path={`/magento-integration`}
          component={MagentoIntegration}
        />
        <LoggedInRoutes
          path={`/posist-integration`}
          component={PosistIntegration}
        />

        <LoggedInRoutes path={`/shopify`} component={Shopify} />
        <LoggedInRoutes exact path={`/vendor-set-up`} component={VendorSetUp} />
        <LoggedInRoutes exact path={`/gen-ai`} component={SqlPredictor} />

        <Route
          path={`/customer_view/:m_id/:cust_id`}
          component={CustomerLoyalty}
        />
        <Route exact path={`/generateOTPForPoints`} component={Ginesys} />
        <Route exact path={`/ginesysClaimCoupon`} component={ClaimCoupon} />
        <Route exact path={`/test`} component={TestComponent} />

        <Route exact path={`/`} render={() => <HomeRoute />} />
      </Suspense>

      <ToastContainer />
    </Fragment>
  );
};

export default withRouter(IndexRoutes);
