import { isEmpty } from "../utils/functions/generalUtils";
import { didToken, merchant, user } from "../utils/functions/getStorageObject";
import isDataSet from "../utils/functions/isDataSet";
import isObjectEmpty from "../utils/functions/IsObjectEmpty";
import { stringifyJson } from "../utils/functions/JsonUtils";

export const _convertObjectToQueryParam = (params = null) => {
  if (isDataSet(params)) {
    let arr = Object.keys(params).map((key) => {
      if (isDataSet(params[key])) {
        if (typeof params[key] === "string" || params[key] instanceof String) {
          if (params[key].trim() !== "") {
            return key + "=" + encodeURIComponent(params[key]);
          } else {
            let val = key + "=" + encodeURIComponent("''");

            return val;
          }
        } else return key + "=" + params[key];
      }
    });
    let urlString = "";

    arr.forEach((element) => {
      if (!isEmpty(element)) {
        if (!isEmpty(urlString)) urlString += "&";
        urlString += element;
      }
    });

    return urlString;
  }
  return "";
};

export const _makeGetRequest = async (
  url = null,
  data = null,
  headers = null
) => {
  if (url) {
    if (isDataSet(data)) {
      data = _convertObjectToQueryParam(data);

      url += `?${data}`;
    }

    return makeRequest(url, null, headers);
  }
  return null;
};
export const _makeRequestWithoutHeader = async (method="GET",url = null, data = null) => {
  if (url) {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: isDataSet(data) ? stringifyJson(data) : data,
    });
  }
  return null;
}

export const _makePostRequest = (url = null, data = null) => {
  if (url) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: isDataSet(data) ? stringifyJson(data) : data,
    });
  }
  return null;
};

export const _makePostRequestV2 = (url = null, data = null, headers = {}) => {
    if (url) {
        const postRequest = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...headers
            },
        };
        postRequest["headers"]["X-Request-Source"] = "ai.xeno";
        postRequest["body"] = isDataSet(data) ? stringifyJson(data) : data;
        return fetch(url, postRequest);
    }
    return null;
};

export const _makePostRequestWithHeader = async (
  url = null,
  data = null,
  useMerchantKey = false,
  requestSource = null
) => {
  // make request for get and post request
  if (url) {
    const postData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          useMerchantKey ? merchant().external_api_key : user().tokenId
        }`,
      },
      body: isDataSet(data) ? stringifyJson(data) : data,
    };
    if (requestSource) postData["headers"]["X-Request-Source"] = "ai.xeno";
    return fetch(url, postData);
  }
  return null;
};

export const _makePostRequestWithHeaderFormData = async (
  url = null,
  data = null,
  useMerchantKey = false,
  requestSource = null
) => {
  if (url) {
    const postData = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          useMerchantKey ? merchant().external_api_key : user().tokenId
        }`,
      },
      body: data, // send data as it is
    };
    if (requestSource) postData["headers"]["X-Request-Source"] = "ai.xeno";
    return fetch(url, postData);
  }
  return null;
};

export const _makePostRequestWithHeaderAndSignal = async (
  url = null,
  data = null,
  useMerchantKey = false,
  requestSource = null,
  signal = null
) => {
  // make request for get and post request
  if (url) {
    const postData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          useMerchantKey ? merchant().external_api_key : user().tokenId
        }`,
      },
      body: isDataSet(data) ? stringifyJson(data) : data,
      signal: signal,
    };
    if (requestSource) postData["headers"]["X-Request-Source"] = "ai.xeno";
    return fetch(url, postData);
  }
  return null;
};

export const _makeRequestWithHeader = async (
  url = null,
  method = "POST",
  data = null,
  useMerchantKey = false,
  requestSource = null,
  merchantid = null
) => {
  // make request for get and post request
  if (url) {
    const postData = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          useMerchantKey ? merchant().external_api_key : user().tokenId
        }`,
      },
    };
    if (requestSource) postData["headers"]["X-Request-Source"] = "ai.xeno";
    if (merchantid) postData["headers"]["X-Merchant-ID"] = merchantid; // Add this line
    if (method !== "GET")
      postData["body"] = isDataSet(data) ? stringifyJson(data) : data;
    return fetch(url, postData);
  }
  return null;
};

export const _makePutRequestWithHeader = async (
  url = null,
  data = null,
  useMerchantKey = false
) => {
  //make request for get and post request
  if (url) {
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          useMerchantKey ? merchant().external_api_key : user().tokenId
        }`,
      },
      body: isDataSet(data) ? stringifyJson(data) : data,
    });
  }
  return null;
};

export const _makeLoginPostRequest = (url = null, data = null) => {
  if (url) {
    if (!isDataSet(data)) {
      data = {};
    }
    return fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: stringifyJson(data),
    });
  }
  return null;
};

export const _makeMagicAuthLoginRequest = (url = null, data = null) => {
  if (url) {
    if (!isDataSet(data)) {
      data = {};
    }
    // url = new URL(url);
    const params = {
      email_id: data.email,
      auth_token: didToken(),
    };
    data = _convertObjectToQueryParam(params);
    url += `?${data}`;
    return fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "no-referrer",
    });
  }
  return null;
};

export const _makeGetRequestWithHeader = async (url = null, data = null) => {
  if (url) {
    const headers = {
      Authorization: "Bearer " + user().tokenId,
    };
    return _makeGetRequest(url, data, headers);
  }
  return null;
};

const makeRequest = async (url = null, postData = null, headers = null) => {
  let requestHeaders = {},
    requestConfig = {};
  if (isDataSet(postData)) {
    requestHeaders = { ...requestHeaders, "Content-Type": "application/json" };
    requestConfig = {
      method: "POST",
      body: isDataSet(postData) ? stringifyJson(postData) : postData,
    };
  }
  if (isDataSet(headers)) {
    requestHeaders = {
      ...requestHeaders,
      Authorization: "Bearer " + user().tokenId,
    };
  }

  if (!isObjectEmpty(requestHeaders)) {
    requestConfig = { ...requestConfig, headers: requestHeaders };
  }

  if (!isObjectEmpty(requestConfig)) {
    return fetch(url, {
      ...requestConfig,
    });
  }

  return fetch(url);
};

export const cacheApiRequest = async (url, response) => {
  if ("caches" in window) {
    const cache = await caches.open(merchant().name);
    cache.put(url, response);
  }
};

export const getCachedRequestData = async (url, response) => {
  if ("caches" in window) {
    const cache = await caches.open(merchant().name);
    return cache.match(url);
  }
};
