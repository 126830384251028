import React from "react";
import { Redirect, Route } from "react-router-dom";
import { merchant } from "../utils/functions/getStorageObject";
import isDataSet from "../utils/functions/isDataSet";
import { isAuthenticated } from "../utils/functions/UserUtils";

function AccessLevelRoutes({ component: Component, ...rest }) {
  const { path } = rest;
  if (isRouteAccessible(path)) {
    return (
      <Route
        {...rest}
        path={path.PAGE_URL}
        render={(props) =>
          isAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        path={path.PAGE_URL}
        render={(props) => <Redirect to="/" />}
      />
    );
  }
}

export function isRouteAccessible({ ACCESS_LEVEL }) {
  if (isDataSet(ACCESS_LEVEL) && ACCESS_LEVEL.length > 0) {
    const userDetail = merchant();
    if (isDataSet(userDetail) && isDataSet(userDetail.userMerchantMap)) {
      const { permission_group } = userDetail.userMerchantMap;
      if (ACCESS_LEVEL.includes(permission_group)) return true;
    }
  }
  return false;
}

export default AccessLevelRoutes;
