import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { sub } from "date-fns";
import Loader from "react-loaders";
import { Row, Col, InputGroup } from "reactstrap";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { merchant } from "utils/functions/getStorageObject";
import { getCampaignHitRateDashboardEmbedUrl } from "../../../Api/index";
import { Redirect } from "react-router-dom";
import classes from "./style.module.css";
import success from "assets/XMC-UI-1-ASSETS/success.svg";
import { getApiRequestData } from "Api/requestWrapper";
import Cross from "assets/XMC-UI-1-ASSETS/Cross.svg";
import {
  getNumericStatsReturnRevenue,
  statusUpdateCampaignDashboard,
} from "Api/index";
import moment from "moment";
import { Snackbar } from "@mui/material";
import ContentLoader from "react-content-loader";

const CampaignHitRate = () => {
  const dashboardContainer = useRef(null);

  const today = new Date();
  const [dashboard, setDashboard] = useState(null);

  const [lastUpdated, setLastUpdated] = useState("");
  const [lastUpdatedLoaded, setLastUpdatedLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openNotificationSnackbar, setOpenNotificationSnackbar] =
    useState(false);

  const closeNotificationSnackbar = () => setOpenNotificationSnackbar(false);
  function handleError(payload) {
    alert("Failed to load business dashboard. Error: ", payload);
  }
  function handleOnLoad(payload) {
    setLoading(false);
  }

  useEffect(() => {
    async function getCampaignLastUpdatedTime() {
      let response = await getApiRequestData(getNumericStatsReturnRevenue, {
        start_date: "2023-01-03",
        end_date: "2023-01-03",
        merchant_id: merchant().id,
      });
      if (response.data && response.data.status_last_updated) {
        // todo - refactor this logic in a single object
        setLastUpdated(moment(response.data["status_last_updated"]).fromNow());
        setLastUpdatedLoaded(true);
      }
    }
    getCampaignLastUpdatedTime();
  }, []);

  useEffect(() => {
    getCampaignHitRateDashboardEmbedUrl()
      .then((res) => res.text())
      .then((data) => {
        if (dashboardContainer.current) {
          const options = {
            url: data,
            container: document.querySelector("#campaignHitRateContainer"),
            scrolling: "no",
            height: "100%",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: true,
          };
          const dashboard = QuickSightEmbedding.embedDashboard(options);
          dashboard.on("error", handleError);
          dashboard.on("load", handleOnLoad);
          setLoading(false);
          setDashboard(dashboard);
        }
      })
      .catch(console.error);
  }, [dashboardContainer]);

  const updateDataCampaignAnalytics = async () => {
    let response = await getApiRequestData(statusUpdateCampaignDashboard, {
      merchant_id: merchant().id,
    });
    setOpenNotificationSnackbar(true);
  };

  return (
    <>
      {merchant().id != "1683" && <Redirect to={"/search/v2"} />}
      <div className={classes.container}>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openNotificationSnackbar}
          onClose={closeNotificationSnackbar}
        >
          <div
            className="p-4"
            style={{
              borderRadius: 6,
              width: 740,
              marginTop: 70,
              background: "#ECFDF5",
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              className="mb-0 pb-0"
              style={{
                color: "#047857",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img className="mr-2" src={success} width="18" height="18" />
              <b>Campaign data will be updated in some time.</b>

              {/* <b>Goals</b> */}
            </p>

            <img
              onClick={closeNotificationSnackbar}
              className="cursor-pointer"
              src={Cross}
              width="20"
              height="20"
            />
          </div>
        </Snackbar>
        <p
          className="ml-5 mb-0 d-flex align-items-center "
          style={{
            height: 50,
          }}
        >
          Campaign performance was last updated
          {!lastUpdatedLoaded ? (
            <ContentLoader>
              <rect x="10" y="60" rx="4" ry="4" width="100" height="30" />
            </ContentLoader>
          ) : (
            <>
              {" "}{lastUpdated}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#005EEB",
                  cursor: "pointer",
                  marginLeft: "1.2rem",
                }}
                onClick={() => {
                  
                  updateDataCampaignAnalytics();
                }}
              >
                Update Campaigns Data
              </span>
            </>
          )}
        </p>
        <div
          className={classes.dashboardContainer}
          ref={dashboardContainer}
          id="campaignHitRateContainer"
        >
          {loading && (
            <div className={classes.loadingIndicatorContainer}>
              <div className={classes.loadingIndicator}>
                <Loader type={"ball-pulse"} />
                <h6>Loading hit rate dashboard</h6>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignHitRate;
