import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updatePasswordUsingOld } from "Api";
import { getApiRequestData } from "Api/requestWrapper";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { isEmpty } from "utils/functions/generalUtils";
import { user } from "utils/functions/getStorageObject";
import isDataSet from "utils/functions/isDataSet";
import tickIcon from "../../assets/XMC-UI-1-ASSETS/Icon-feather-check.png";
const style = {
  width: "60%",
  maxWidth: "100rem",
  marginTop: "0px",
  height: "100vh",
};

const UpdatePassword = ({
  modalOpen,
  setModalOpen,
  updatePasswordModal,
  setupdatePasswordModal,
}) => {
  const [visibleOld, setVisibleOld] = useState(false);
  const [visibleNew, setVisibleNew] = useState(false);
  const [visibleNewVerify, setVisibleNewVerify] = useState(false);
  const [error, setError] = useState(null);

  const oldPassword = useSetValue("");
  const password = useSetValue("");
  const verifyPassword = useSetValue("");
  const [errorLengthNewPassword, setErrorLengthNewPassword] = useState(true);
  const [errorMatchVerifyPassword, setErrorMatchVerifyPassword] = useState(
    true
  );
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (
      isEmpty(oldPassword.value) ||
      password.value.length < 5 ||
      password.value != verifyPassword.value
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [disable, password.value, verifyPassword.value, oldPassword.value]);
  useEffect(() => {
    if (password.value.length >= 5) {
      setErrorLengthNewPassword(false);
    } else {
      setErrorLengthNewPassword(true);
    }
  }, [password.value, verifyPassword.value]);
  useEffect(() => {
    if (
      verifyPassword.value == password.value &&
      password.value.length != 0 &&
      verifyPassword.value.length != 0
    ) {
      setErrorMatchVerifyPassword(false);
    } else {
      setErrorMatchVerifyPassword(true);
    }
  }, [password.value, verifyPassword.value]);
  const OpenModal = () => {
    setupdatePasswordModal(true);
    const timer = setTimeout(() => {
      setupdatePasswordModal(false);
    }, 4000);
    return () => clearTimeout(timer);
  };
  const updatePasswordOtp = async () => {
    let errors = {};
    if (validPassword(password) && validPassword(verifyPassword)) {
      if (password.value.length < 5) {
        errors["length"] = "should contain atleast 5 digits";
      }
      if (password.value !== verifyPassword.value) {
        errors["equal"] = "password does not match";
      }
    }

    if (Object.keys(errors).length === 0) {
      let response = await getApiRequestData(updatePasswordUsingOld, {
        user_id: user().userId,
        old_password: oldPassword.value,
        password: password.value,
      });

      if (response.status) {
        if (response.data.status) {
          setModalOpen(false);
          OpenModal();
        } else {
          errors["oldPassword"] = response.data.message;
          setError(errors);
        }
      } else {
        alert(response.data.message);
      }
    } else {
      setError(errors);
    }
  };

  const passwordIcon = (visible, setVisible) => (
    <FontAwesomeIcon
      icon={visible ? faEye : faEyeSlash}
      onClick={() => setVisible(!visible)}
    />
  );
  const handleNewPasswordChange = (event) => {
    let newPasswordValue = event.target.value;

    if (newPasswordValue.length >= 5) {
      setErrorLengthNewPassword(false);
    } else {
      setErrorLengthNewPassword(true);
    }
  };

  const PasswordType = (visible) => (visible ? "text" : "password");
  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => setModalOpen(!modalOpen)}
      centered
      style={style}
      id="modal-content"
    >
      <ModalHeader
        className="pl-4 pt-4 personlized-link"
        toggle={() => setModalOpen(!modalOpen)}
      >
        <span style={{ fontWeight: "700" }}>Change Password</span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column body align-items-center justify-content-between">
        <div className="update-password-form-container">
          <Form style={{ margin: "40px 0px" }}>
            <Row
              form
              style={{
                width: "460px",
                marginRight: "100px",
                marginLeft: "100px",
              }}
            >
              <Col sm={12}>
                <FormGroup
                  className="w-100"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="modal-input-container">
                    <Label
                      for="password"
                      style={{ fontWeight: "600", fontSize: "14px" }}
                    >
                      Old Password
                    </Label>
                    <Input
                      type={PasswordType(visibleOld)}
                      id="Oldpassword"
                      {...oldPassword}
                      className="update-password-inputs"
                    />
                    <span className="toggle-eye__forgot-pass-modal">
                      {passwordIcon(visibleOld, setVisibleOld)}
                    </span>
                  </div>
                </FormGroup>
                {isDataSet(error) && isDataSet(error["oldPassword"]) ? (
                  <p style={{ marginTop: "-15px", color: "#fe6565" }}>
                    {error["oldPassword"]}
                  </p>
                ) : null}
              </Col>
              <Col sm={12}>
                <FormGroup
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="modal-input-container">
                    <Label
                      for="password"
                      style={{ fontWeight: "600", fontSize: "14px" }}
                    >
                      New Password
                    </Label>
                    <Input
                      // className="search-input form-control"
                      onChange={(event) => handleNewPasswordChange(event)}
                      type={PasswordType(visibleNew)}
                      id="Newpassword"
                      {...password}
                      className="update-password-inputs"
                    />
                    <span className="toggle-eye__forgot-pass-modal">
                      {passwordIcon(visibleNew, setVisibleNew)}
                    </span>
                  </div>
                </FormGroup>
                {isDataSet(error) && isDataSet(error["length"]) ? (
                  <p style={{ marginTop: "-15px", color: "#fe6565" }}>
                    {error["length"]}
                  </p>
                ) : null}
                {errorLengthNewPassword ? (
                  <p style={{ marginTop: "-15px", color: "#fe6565" }}>
                    {"should contain atleast five digits"}
                  </p>
                ) : (
                  <p style={{ marginTop: "-15px", color: "#5ec02c" }}>
                    <img src={tickIcon} style={{ height: "10px" }} />{" "}
                    {"should contain atleast five digits"}
                  </p>
                )}
              </Col>
              <Col sm={12}>
                <FormGroup className="d-flex align-items-center flex=direction-column ">
                  <div className="modal-input-container">
                    <Label
                      for="examplePassword"
                      style={{ fontWeight: "600", fontSize: "14px" }}
                    >
                      New Password
                    </Label>
                    <Input
                      type={PasswordType(visibleNewVerify)}
                      name="Newpassword"
                      {...verifyPassword}
                      className="update-password-inputs"
                    />
                    <span className="toggle-eye__forgot-pass-modal">
                      {passwordIcon(visibleNewVerify, setVisibleNewVerify)}
                    </span>
                  </div>
                </FormGroup>
                {isDataSet(error) && isDataSet(error["equal"]) ? (
                  <p
                    style={{
                      marginTop: "-15px",
                      color: "#fe6565",
                      marginBottom: "0px",
                    }}
                  >
                    {error["equal"]}
                  </p>
                ) : null}
                {errorMatchVerifyPassword ? (
                  <p style={{ marginTop: "-15px", color: "#fe6565" }}>
                    {"password does not match"}
                  </p>
                ) : (
                  <p style={{ marginTop: "-15px", color: "#5ec02c" }}>
                    <img src={tickIcon} style={{ height: "10px" }} />{" "}
                    {"Password Matches"}
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        <div className="d-flex align-items-center justify-content-center mb-0 w-100">
          {disable ? (
            <Button
              className="save-button-loyalty update-pass-modal-save-btn"
              color="primary"
              style={{ opacity: "50%" }}
            >
              Save Password
            </Button>
          ) : (
            <Button
              className="save-button-loyalty update-pass-modal-save-btn"
              onClick={() => updatePasswordOtp()}
              color="primary"
              /*style={{ width: "30%" }}*/
            >
              Save Password
            </Button>
          )}
          <a
            className="btn-lg btn update-pass-remember-text btn-link"
            style={{
              fontSize: "14px",

              fontWeight: "200",
            }}
            onClick={() => setModalOpen(!modalOpen)}
          >
            Cancel
          </a>{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};
function validPassword(password) {
  if (isDataSet(password) && !isEmpty(password.value)) {
    return true;
  }
  return false;
}
function useSetValue(initialValue) {
  const [value, setValue] = useState(initialValue);

  function onChange(event) {
    setValue(event.target.value);
  }

  return {
    value,
    onChange,
  };
}

export default UpdatePassword;
