import ParseJson from "./ParseJson";
import globalContextStore from "../../stores/_contextStores";

const { getState } = globalContextStore;

const merchant = () => ParseJson(localStorage.getItem("merchant"), null);
const user = () => ParseJson(localStorage.getItem("user"), null);
const customer = () => ParseJson(localStorage.getItem("customer"), null);
const userDetails = () => ParseJson(localStorage.getItem("userDetails"), null);
const didToken = () => localStorage.getItem("didToken");
const setUpIntegrationData = () =>
  ParseJson(localStorage.getItem("currentIntegration"), null);
const VendorDetails = () =>
  ParseJson(localStorage.getItem("vendorDetails"), null);
const getCampaignDetails = () => {
  let campaignDetail = ParseJson(
    sessionStorage.getItem("campaignDetail"),
    null
  );
  let combinationData = ParseJson(
    sessionStorage.getItem("combinationData"),
    null
  );
  if (combinationData != null) {
    campaignDetail = { ...campaignDetail, ...combinationData };
  }
  let channelData = ParseJson(sessionStorage.getItem("channelData"), null);
  if (channelData != null) {
    campaignDetail = { ...campaignDetail, ...channelData };
  }
  return campaignDetail;
};

const fetchListing = () =>
  ParseJson(sessionStorage.getItem("fetchCampaign"), null);
const getCurrentCampaignReloadCount = () =>
  ParseJson(sessionStorage.getItem("currentCampaignReloadCount"), null);
const getCurrentJourneyReloadCount = () =>
  ParseJson(sessionStorage.getItem("currentJourneyReloadCount"), null);
const getJourneyDetails = () =>
  ParseJson(sessionStorage.getItem("journeyDetail"), null);
const getJourneyContent = () =>
  ParseJson(sessionStorage.getItem("journeyContent"), null);
// const getSavedJourneyData = () =>
//   ParseJson(sessionStorage.getItem("savedJourneyData"), null);

const getMerchantOMConfig = () =>
  ParseJson(sessionStorage.getItem("merchantOMConfig"), null);

const getSavedJourneyData = () =>
  ParseJson(sessionStorage.getItem("savedJourneyData"), null);

const getSavedJourneyListData = () =>
  ParseJson(sessionStorage.getItem("savedJourneyListData"), null);
const getJourneyTriggerList = () =>
  ParseJson(sessionStorage.getItem("journeyTriggerList"), null);

const getSegmentDetails = () =>
  ParseJson(sessionStorage.getItem("segmentDetails"), null);

const getCampaignPrefillData = () =>
  ParseJson(sessionStorage.getItem("campaignPrefillData"), null);

const getJourneyPreviewDetails = () =>
  ParseJson(sessionStorage.getItem("isJourneyPreview"), null);

const getFetchListing = () =>
  ParseJson(sessionStorage.getItem("fetchListing"), null);
export {
  merchant,
  user,
  customer,
  getCampaignDetails,
  userDetails,
  fetchListing,
  getJourneyDetails,
  getSavedJourneyData,
  getSavedJourneyListData,
  getJourneyContent,
  getJourneyTriggerList,
  getSegmentDetails,
  getCampaignPrefillData,
  getCurrentCampaignReloadCount,
  getCurrentJourneyReloadCount,
  getJourneyPreviewDetails,
  didToken,
  setUpIntegrationData,
  VendorDetails,
  getFetchListing,
  getMerchantOMConfig,
};
