import React, {useEffect, useRef, useState} from "react";
import {getLoyaltyDashboardEmbedUrl} from "../../../Api";
import {merchant} from "../../../utils/functions/getStorageObject";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import Loader from "react-loaders";

const LoyaltyDetailsDashboard = () => {
    const dashboardContainer = useRef(null);
    const [dashboard, setDashboard] = useState(null);
    const [loading, setLoading] = useState(true);

    function handleError(payload) {
        alert("Failed to load loyalty dashboard. Error: ", payload);
    }

    function handleOnLoad(payload) {
        setLoading(false);
    }

    useEffect(() => {
        getLoyaltyDashboardEmbedUrl()
            .then((res) => res.text())
            .then((data) => {
                if (dashboardContainer.current) {
                    const options = {
                        url: data,
                        container: document.querySelector("#embeddingContainer"),
                        parameters: {
                            dashboardkey: merchant().sandboxAccount === 2 ? 'eb6c495cd2' : merchant().dashboard_key,
                        },
                        scrolling: "no",
                        height: "100%",
                        width: "100%",
                        locale: "en-US",
                        footerPaddingEnabled: true,
                    };
                    const dashboard = QuickSightEmbedding.embedDashboard(options);
                    dashboard.on("error", handleError);
                    dashboard.on("load", handleOnLoad);
                    setLoading(false);
                    setDashboard(dashboard);
                }
            })
            .catch(console.error);
    }, [dashboardContainer]);

    return (
        <div className="mt-4 mb-1"
             ref={dashboardContainer}
             style={{
                 height: "calc(100vh - 120px)",
             }}
             id="embeddingContainer"
        >
            {loading && (
                <div className="text-center">
                    <Loader type={"ball-pulse"} />
                    <h6>Loading Loyalty Dashboard</h6>
                </div>
            )}
        </div>
    );
}

export default LoyaltyDetailsDashboard;