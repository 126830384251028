import create from "zustand";
import { immer } from "zustand/middleware/immer";

const useGlobalStore = create(
  immer((set, get) => ({
    listingNotification: {
      status: false,
      time: "s", // s, and l
      closeNotification: () => {
        set((state) => {
          state.listingNotification.status = false;
        });
      },
      openNotification: (message, type = "success", time = "s") => {
        set((state) => {
          state.listingNotification.status = true;
          state.listingNotification.message = message;
          state.listingNotification.type = type;
          state.listingNotification.time = time;
        });
      },
      message: "",
      setMesssage: (message) => {
        set((state) => {
          state.listingNotification.message = message;
        });
      },
      type: "success",
    },
  }))
);

export default useGlobalStore;
