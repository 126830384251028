import isDataSet from "../utils/functions/isDataSet";
import { USER_LEVEL } from "../utils/globalConstants";

export const XENO_ROUTES = {
  SEARCH: buildRoutes({
    pageUrl: "/search",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.CDP,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  SEGMENTATION: buildRoutes({
    pageUrl: "/segmentation",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  JOURNEY: buildRoutes({
    pageUrl: "/journey",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  CAMPAIGN: buildRoutes({
    pageUrl: "/campaign",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  PERSONA_INSIGHTS: buildRoutes({
    pageUrl: "/personaInsights",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  PERSONALIZED: buildRoutes({
    pageUrl: "/personalized",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  DASHBOARD: buildRoutes({
    pageUrl: "/dashboard",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  LOGIN: buildRoutes({
    pageUrl: "/login",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  BUSINESSREPORT: buildRoutes({
    pageUrl: "/business-reports",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  CAMPAIGNDASHBOARD: buildRoutes({
    pageUrl: "/campaignDashboard",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  BUSINESSDASHBOARD: buildRoutes({
    pageUrl: "/businessDashboard",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  MYDASHBOARD: buildRoutes({
    pageUrl: "/myDashboard",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  CUSTOMERDETAILSDASHBOARD: buildRoutes({
    pageUrl: "/customerDetailsDashboard",
    pageTitle: "Customer Details Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  ORDERDETAILSDASHBOARD: buildRoutes({
    pageUrl: "/orderDetailsDashboard",
    pageTitle: "Order Details Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  OTPDASHBOARD: buildRoutes({
    pageUrl: "/otpDashboard",
    pageTitle: "OTP Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
    ],
  }),
  CAMPAIGNDASHBOARDV2: buildRoutes({
    pageUrl: "/campaignDashboard/v2",
    pageTitle: "Campaign Dashboard v2",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  FABINDIADASHBOARD: buildRoutes({
    pageUrl: "/FabIndiaDashboard",
    pageTitle: "Fabindia Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),

  MANAGEMENTKPIREPORT: buildRoutes({
    pageUrl: "/ManagementKPIFabIndia",
    pageTitle: "Management KPI Report",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),

  ANALYTICKPIREPORT: buildRoutes({
    pageUrl: "/AnalyticsReportKPIFabIndia",
    pageTitle: "Analytics KPI Report",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),

  POINTSEXPIRYREPORT: buildRoutes({
    pageUrl: "/PointsExpiryFabIndia",
    pageTitle: "Points Expiry Report",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),


  WWIANALYSISDASHBOARD: buildRoutes({
    pageUrl: "/WWIAnalysisDashboard",
    pageTitle: "WWI Analysis Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],

  }),

  TIEROVERVIEWDASHBOARD: buildRoutes({
    pageUrl: "/TierOverviewDashboard",
    pageTitle: "Tier Overview Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],

  }),



  SOCIALFRAUDDETECTIONDASHBOARD: buildRoutes({
    pageUrl: "/SocialFraudReportDashboard",
    pageTitle: "Fraud Report Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  BEINGHUMANCOUPONREDEMPTIONDASHBOARD: buildRoutes({
    pageUrl: "/BeingHumanCouponRedemptionDashboard",
    pageTitle: "Coupon Redemption Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  LOYALTYDASHBOARD: buildRoutes({
    pageUrl: "/LoyaltyDashboard",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  HITRATEDASHBOARD: buildRoutes({
    pageUrl: "/campaignHitRateDashboard",
    pageTitle: "Campaign HitRate Dashboard",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,
    ],
  }),
  LOYALTY: buildRoutes({
    pageUrl: "/loyalty",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  OFFERS: buildRoutes({
    pageUrl: "/offers",
    pageTitle: "Offer Management",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  SETUP: buildRoutes({
    pageUrl: "/setup",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  UTILITIES: buildRoutes({
    pageUrl: "/utilities",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  INTEGRATIONS: buildRoutes({
    pageUrl: "/integrations",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  PERSONA_CONFIG: buildRoutes({
    pageUrl: "/persona-config",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
    ],
  }),
  HOME: buildRoutes({
    pageUrl: "/",
    pageTitle: "",
    accessLevel: [
      USER_LEVEL.SUPER,
      USER_LEVEL.CHECKIN,
      USER_LEVEL.ACTUALS,
      USER_LEVEL.STAFF,
      USER_LEVEL.CUSTOMER_AND_INSIGHTS,

    ],
  }),
};

export const XENO_ROUTES_2 = {
  SEARCH: "/search",
  SEGMENTATION: "/segmentation",
  JOURNEY: "/journey",
  CAMPAIGN: "/campaign",
  PERSONA_INSIGHTS: "/personaInsights",
  PERSONALIZED: "/personalized",
  DASHBOARD: "/dashboard",
  HOME: "/",
  LOGIN: "/login",
  BUSINESSREPORT: "/business-reports",
  CAMPAIGNDASHBOARD: "/campaignDashboard",
  LOYALTY: "/loyalty",
  SETUP: "/setup",
};

export function buildRoutes({ pageUrl, pageTitle, accessLevel }) {
  return {
    PAGE_URL: pageUrl,
    PAGE_TITLE: pageTitle,
    ACCESS_LEVEL: accessLevel,
  };
}

export const getRoutePageUrl = (routeObject) => {
  if (isDataSet(routeObject)) {
  }
  return null;
};
