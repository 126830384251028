import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "../utils/functions/UserUtils";
import { trackRoute } from "Components/utils/Analytics";

function LoggedInRoutes({ component: Component, ...rest }) {
  const { track } = rest;
  const { category, pageName, properties } = rest;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          track ? (
            trackRoute(<Component {...props} />, category, pageName, properties)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default LoggedInRoutes;
