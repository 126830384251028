import isObjectEmpty from "./IsObjectEmpty";
import { stringifyJson } from "./JsonUtils";
import globalContextStore from "../../stores/_contextStores";

const { setState } = globalContextStore;

const setFetchListing = (value) =>
  sessionStorage.setItem("fetchListing", value);
const setCurrentCampaignReloadCount = (value) =>
  sessionStorage.setItem("currentCampaignReloadCount", value);

const setCurrentJourneyReloadCount = (value) =>
  sessionStorage.setItem("currentJourneyReloadCount", value);
const setJourneyDetail = (value) =>
  sessionStorage.setItem("journeyDetail", value);
const setJourneyContent = (value) =>
  sessionStorage.setItem("journeyContent", value);
const setJourneyTriggerList = (value) =>
  sessionStorage.setItem("journeyTriggerList", value);
const setCampaignDetails = (value) => {
  let tempObj = JSON.parse(JSON.stringify(value));
  const obj = JSON.parse(
    JSON.stringify({
      allCombinationTypes: value.allCombinationTypes,
      oldCombinations: value.oldCombinations,
      combination: value.combination,
    })
  );
  if (!isObjectEmpty(obj)) {
    for (const property in obj) {
      delete tempObj[property];
    }
  }

  const channelData = JSON.parse(
    JSON.stringify({
      personalizedAppNotification: value.personalizedAppNotification,
      personalizedEmail: value.personalizedEmail,
      personalizedFb: value.personalizedFb,
      personalizedSms: value.personalizedSms,
      personalizedWhatsapp: value.personalizedWhatsapp,
    })
  );
  if (!isObjectEmpty(channelData)) {
    for (const property in channelData) {
      delete tempObj[property];
    }
  }
  sessionStorage.setItem("campaignDetail", stringifyJson(tempObj));
  sessionStorage.setItem("combinationData", stringifyJson(obj));
  sessionStorage.setItem("channelData", stringifyJson(channelData));
};
const setSegmentDetails = (value) => {
  sessionStorage.setItem("segmentDetails", stringifyJson(value));
};

const setMerchantOMConfig = (value) => {
  sessionStorage.setItem("merchantOMConfig", JSON.stringify(value));
};

const setCampaignPrefillData = (value) =>
  sessionStorage.setItem("campaignPrefillData", stringifyJson(value));

const setJourneyPreviewDetails = (value) =>
  sessionStorage.setItem("isJourneyPreview", stringifyJson(value));

const setJourneyData = (value) => {
  sessionStorage.setItem("savedJourneyData", stringifyJson(value));
};

const setSavedJourneyFilters = (value) => {
  sessionStorage.setItem("savedJourneyFilters", stringifyJson(value));
};

export {
  setFetchListing,
  setJourneyDetail,
  setJourneyTriggerList,
  setJourneyContent,
  setCampaignDetails,
  setSegmentDetails,
  setCampaignPrefillData,
  setCurrentCampaignReloadCount,
  setCurrentJourneyReloadCount,
  setJourneyPreviewDetails,
  setJourneyData,
  setSavedJourneyFilters,
  setMerchantOMConfig,
};
