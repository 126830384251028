import cx from "classnames";
import ChangePassword from "Components/Loginmodals/ChangePassword";
import UpdatedPasswordModal from "Components/Loginmodals/UpdatedPasswordModal";
import * as Sentry from "@sentry/react";
import React, { Fragment, useEffect, useState, useRef } from "react";
import GlobalMuiXenoTheme from "GlobalMuiXenoTheme";
import { createContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Collapse, Navbar } from "reactstrap";
import { merchant, user, userDetails } from "utils/functions/getStorageObject";
import isDataSet from "utils/functions/isDataSet";
import { stringifyJson } from "utils/functions/JsonUtils";
import XenoLogo from "./assets/XMC-UI-1-ASSETS/Xenologo.png";
import UserProfile from "./Components/UserProfile";
import XenoNavbar from "./Components/XenoNavbar";
import IndexRoutes from "./routes";
import ErrorBoundary from "Components/ErrorBoundary/ErrorBoundary";
import { isAuthenticated } from "./utils/functions/UserUtils";
import { pdfjs } from "react-pdf";

import NotificationSnackbar from "Components/NotificationSnackbar";
import CreationBanner from "./Containers/CampaignCreation/Creation/Personalized/Utilities/CreationBanner";

//import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Import styles for annotations (optional)
pdfjs.GlobalWorkerOptions.workerSrc = require("./../node_modules/react-pdf/dist/pdf.worker.entry.js");
export const isNavigationBar = createContext();
if (!String.prototype.replaceAll) {
  // polyfill code added as quick shortcut for fixing gineysis probelmsss
  // ! this is dangerous
  // todo - find better way to polyfill
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}

const handleClickEvents = (e) => {
  const elements = e.path; // this will get all the elements in the event bubble
  if (elements)
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.dataset && element.dataset.clickanalytics === "1") {
        window.analytics.track(element.dataset.clickanalyticsmodule, {
          clickName: element.dataset.clickanalyticsname,
          merchant: merchant().id,
        });
      }
    }
};

function Main({ match, location }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordUpdatedModal, setPasswordUpdatedModal] = useState(false);
  const [showNavbar, setShowNavBar] = useState(true);
  const history = useHistory();
  useEffect(() => {
    let value = sessionStorage.getItem("modalOpenChangePassword");
    if (value === "true") {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
    history.listen((e) => {
      if (e.pathname) window.analytics.page(e.pathname);
    });
    document.addEventListener("click", handleClickEvents);
    return () => {
      document.removeEventListener("click", handleClickEvents);
    };
  }, []);

  useEffect(() => {
    if (isDataSet(match) && isDataSet(match.location)) {
      let str = match.location.pathname.replaceAll("/", " ");
      const arr = str.split(" ");
      var array = new Array();
      let j = 0;
      for (var i = 0; i < arr.length; i++) {
        if (!isNaN(arr[i])) {
          continue;
        }
        array[j++] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = array.join(" ");
      document.title = str2;
    }
  }, [stringifyJson(match)]);

  useEffect(() => {
    if (userDetails()) {
      const {
        id: userId,
        name: userName,
        email: userEmail,
      } = { ...userDetails() };
      const { id: merchantId, name: merchantName } = merchant();
      const userRole =
        userEmail && userEmail.includes("xeno.in")
          ? "internal_user"
          : "external_user";
      if (userRole !== "internal_user")
        window.zapscale.init(
          "6400557ad3adeb8026be29a3",
          {
            product_name: "ai.xeno", // ---> name of the product
            organization_id: merchantId, // ---> id of the company/organization
            organization_name: merchantName, // ---> organization's name
            user_id: userId, // ---> unique id of the authenticated user
            user_name: userName, // ---> user's name
            user_email: userEmail, // ---> user's email
            role_id: userRole, // ---> unique id of the user's role
            role_name: userRole, // ---> role name
          },
          {
            development:
              window.location && window.location.href.indexOf("ai.xeno.in") < 0, // ---> (optional) set it to true in development/staging environment
          }
        );
    }
  }, [window.location]);

  return (
    <Fragment>

      <NotificationSnackbar />

      <GlobalMuiXenoTheme>
        <ChangePassword
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          updatePasswordModal={passwordUpdatedModal}
          setupdatePasswordModal={setPasswordUpdatedModal}
        />
        <UpdatedPasswordModal
          modalOpen={passwordUpdatedModal}
          setModalOpen={setPasswordUpdatedModal}
        />
        <div className=" app-container app-theme-white d-flex">
          {/*<CreationBanner children={"Site will be under maintenance from 05/03/24 9:30PM to 06/03/24 12:30AM"}/>*/}
          <isNavigationBar.Provider value={[showNavbar, setShowNavBar]}>
            {isAuthenticated() && showNavbar ? (
              <Navbar
                light
                expand="md"
                className="bg-primary"
                style={{ height: "60px" }}
                fixed="top"
              >
                <a href={"#/"}>
                  <img
                    src={XenoLogo}
                    style={{
                      height: "36px",
                      width: "5rem",
                      marginRight: "40px",
                    }}
                    className="pointer"
                  />
                </a>

                <Collapse isOpen={true} navbar>
                  <XenoNavbar />
                  <UserProfile />
                </Collapse>

              </Navbar>
            ) : null}
            <main
              className={cx({
                "mt-5 ml-5 mr-5": isAuthenticated() && showNavbar,
              })}
            >
              <IndexRoutes />
            </main>
          </isNavigationBar.Provider>
        </div>

      </GlobalMuiXenoTheme>
    </Fragment>
  );
}
export default Sentry.withErrorBoundary(Sentry.withProfiler(withRouter(Main)), {
  fallback: (props) => <ErrorBoundary {...props} />,
  showDialog: false,
});
