import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { sub } from "date-fns";
import Loader from "react-loaders";
import { Row, Col, InputGroup, Button } from "reactstrap";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import {merchant, user} from "utils/functions/getStorageObject";
import {getAllLocation, getBusinessDashboardEmbedUrl, getDashboardConfig} from "../../../Api/index";
import {getApiRequestData} from "../../../Api/requestWrapper";
import isDataSet from "../../../utils/functions/isDataSet";
import SelectUtil from "../selectUtil";
import {stringifyJson} from "../../../utils/functions/JsonUtils";

export const BusinessDashboard = () => {
  const allStores = {
    id: 0,
    label: "All Stores",
    value: 0,
  };
  const allCountries = {
    id: 0,
    label: "All Countries",
    value: 0,
  };
  const dashboardContainer = useRef(null);
  const today = new Date();
  const [config, setConfig] = useState(null);
  const [filter, setFilter] = useState({});
  const [dashboard, setDashboard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [storeData, setStoreData] = useState(null);
  const [storeNameDropDown, setStoreNameDropDown] = useState([allStores]);
  const [selectedStores, setSelectedStores] = useState([allStores]);
  const [countryNameDropDown, setCountryNameDropDown] = useState([allCountries]);
  const [selectedCountries, setSelectedCountries] = useState([allCountries]);
  const [startDate, setStartDate] = useState(
      sub(today, {
        months: 3,
      })
  );
  const [endDate, setEndDate] = useState(today);

  function handleError(payload) {
    alert("Failed to load business dashboard. Error: ", payload);
  }

  function handleOnLoad(payload) {
    setLoading(false);
  }

  function joinWithCommas(array) {
    return array.map(item => item.label).join(",");
  }

  function handleDateRangeSelect(event, picker) {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
    if (dashboard != null && (!filter.country && !filter.store)) {
      const updatedParameters = {...dashboard.parameters, StartDate: startDate, EndDate: endDate};
      dashboard.setParameters(updatedParameters);
    }
  }

  function handleFilterChange() {
    if (dashboard != null) {
      let updatedParameters = {...dashboard.parameters};
      updatedParameters = {...updatedParameters, StartDate: startDate, EndDate: endDate};

      if (selectedCountries.length > 0 && selectedCountries[0].id !== 0) {
        updatedParameters = {...updatedParameters, Country: selectedCountries.map(country => country.label)};
      } else {
        updatedParameters = {...updatedParameters, Country: "[ALL]"};
      }
      if (selectedStores.length > 0 && selectedStores[0].id !== 0) {
        updatedParameters = {...updatedParameters, Store: selectedStores.map(store => store.label)};
      } else {
        updatedParameters = {...updatedParameters, Store: "[ALL]"};
      }
      dashboard.setParameters(updatedParameters);
    }
  }

  useEffect(() => {
    getBusinessDashboardEmbedUrl()
        .then((res) => res.text())
        .then((data) => {
          if (dashboardContainer.current) {
            const options = {
              url: data,
              container: document.querySelector("#embeddingContainer"),
              parameters: {
                MerchantID: merchant().sandboxAccount === 2 ? 'eb6c495cd2' : merchant().dashboard_key,
                StartDate: startDate,
                EndDate: endDate,
              },
              scrolling: "no",
              height: "100%",
              width: "100%",
              locale: "en-US",
              footerPaddingEnabled: true,
            };
            const dashboard = QuickSightEmbedding.embedDashboard(options);
            dashboard.on("error", handleError);
            dashboard.on("load", handleOnLoad);
            setLoading(false);
            setDashboard(dashboard);
          }
        })
        .catch(console.error);
  }, [dashboardContainer]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getApiRequestData(getDashboardConfig, null, merchant().id);
        if (response.status && isDataSet(response.data.result)) {
          const configData = JSON.parse(response.data.result);
          setConfig(configData);

          const updatedFilter = {};
          if (configData.businessDashboard?.countryFilter) {
            updatedFilter.country = configData.businessDashboard?.countryFilter;
          }
          if (configData.businessDashboard?.storeFilter) {
            updatedFilter.store = configData.businessDashboard?.storeFilter;
          }
          setFilter(updatedFilter);
        }
      } catch (error) {
        console.error('Error parsing config data:', error);
      }
    };

    if (config == null) {
      fetchData();
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
        const storeResponse = await getApiRequestData(getAllLocation, null, merchant().id);
        if (storeResponse.status && isDataSet(storeResponse.data)) {
            setStoreData(storeResponse.data);
        }
    }

    if (storeData == null && isDataSet(config) && (filter.country || filter.store)){
        fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (isDataSet(storeData)) {
      const countryNames = Array.from(new Set(storeData.map(location => location.country)));
      const countryOptions = countryNames.map(country => ({
        id: country,
        label: country,
        value: country
      }));

      countryOptions.unshift(allCountries);
      setCountryNameDropDown(countryOptions);
    }
  }, [stringifyJson(storeData)]);

  useEffect(() => {
    if (isDataSet(storeData)) {
      let filteredStores = storeData;
      if (selectedCountries.length > 0 && selectedCountries[0].id !== 0) {
        const filteredCountries = selectedCountries.map(country => country.label);
        filteredStores = filteredStores.filter(store => filteredCountries.includes(store.country));
      }
      const storeOptions = filteredStores.map(location => ({
        id: location.id,
        label: location.area,
        value: location.id
      }));
      storeOptions.unshift(allStores);
      setStoreNameDropDown(storeOptions);
    }
  }, [stringifyJson(selectedCountries), stringifyJson(storeData)]);

  return (
      <>
        <Row className="mt-3 mb-1 justify-content-end">
          { filter.country && (
              <Col sm={4}>
                <SelectUtil
                    options={countryNameDropDown}
                    setFilter={setSelectedCountries}
                    defaultValue={allCountries}
                    filter={selectedCountries}
                />
              </Col>
          )}
          { filter.store && (
              <Col sm={4}>
                <SelectUtil
                    options={storeNameDropDown}
                    setFilter={setSelectedStores}
                    defaultValue={allStores}
                    filter={selectedStores}
                />
              </Col>
          )}
          <Col sm={3}>
            <InputGroup>
              <DateRangePicker
                  initialSettings={{
                    startDate: startDate,
                    endDate: endDate,
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                  }}
                  onApply={handleDateRangeSelect}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </InputGroup>
          </Col>
          { (filter.country || filter.store) && (
              <Col sm={1}>
                <Button size="lg" color="xeno-blue" onClick={handleFilterChange}>
                  Apply
                </Button>
              </Col>
          )}
        </Row>
        <div
            ref={dashboardContainer}
            style={{
              height: "calc(100vh - 120px)",
            }}
            id="embeddingContainer"
        >
          {loading && (
              <div className="text-center">
                <Loader type={"ball-pulse"} />
                <h6>Loading Business Dashboard</h6>
              </div>
          )}
        </div>
      </>
  );
};

