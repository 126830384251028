import React from "react";
import { InView } from "react-intersection-observer";
import { merchant, userDetails } from "../../../utils/functions/getStorageObject";


// todo - put this in utils folder
// * for tracking first render/mounting of a component
function trackComponentRender(WrappedComponent, options) {
  const { event, properties } = options;
  class TrackedComponent extends React.Component {
    componentDidMount() {
      window.analytics.track(event, properties);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return TrackedComponent;
}
function trackRoute(WrappedComponent, category, pageName, properties) {
  class TrackedComponent extends React.Component {
    componentDidMount() {
      window.analytics.page(category, pageName, properties);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return TrackedComponent;
}

// * tracking the components appearance in user viewport
function trackComponentInView(WrappedComponent, options) {
  const { event, properties, threshold } = options;
  properties.brandName = merchant()["name"];
  properties.brandId = merchant()["id"];
  properties.userEmail = userDetails()["email"];
  class TrackedComponent extends React.Component {
    render() {
      return (
        <InView
          triggerOnce
          threshold={threshold}
          onChange={(inView, entry) => {
            if (inView) window.analytics.track(event, properties);
          }}
        >
          <WrappedComponent {...this.props} />
        </InView>
      );
    }
  }
  return TrackedComponent;
}

// * tracking click events - for wrapping onClick event functions
function trackComponentClick(wrappedFunction, options) {}

function trackUserLogin(id, properties) {
  window.analytics.identify(id, properties);
}

export {
  trackComponentRender,
  trackComponentInView,
  trackComponentClick,
  trackUserLogin,
  trackRoute,
};
