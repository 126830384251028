import isDataSet from "./isDataSet";

function isObjectEmpty(obj = {}) {
  if (isDataSet(obj)) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
  }

  return true;
}

export default isObjectEmpty;
