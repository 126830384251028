import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import avatar1 from "../../assets/XMC-UI-1-ASSETS/Ashish.jpg";
import isDataSet from "../../utils/functions/isDataSet";
import { stringifyJson } from "../../utils/functions/JsonUtils";
import UserLoggedInView from "./UserLoggedInView";
import { m } from "../../Containers/login/magicLogin";
import { trackUserLogin } from "Components/utils/Analytics";
import { getMerchantOMConfig } from "Api";
import { getMerchantOMConfig as getMerchantOMConfigFromStorage } from "utils/functions/getStorageObject";
import { get, isNull } from "lodash";
import { setMerchantOMConfig } from "utils/functions/setLocalStorageObject";

const Userlogout = async (history) => {
  try {
    await localStorage.removeItem("user");
    await localStorage.removeItem("merchant");
    await localStorage.removeItem("userDetails");
    await sessionStorage.removeItem("modalOpenChangePassword");
    history.push(history.location.pathname);
    await m.user.logout();
  } catch {
    console.error("logout error");
  }
};

const UserProfile = withRouter(
  ({ history, modalChangePassword, setChangePassword }) => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    let merchant = localStorage.getItem("merchant");
    merchant = JSON.parse(merchant);

    if (isDataSet(user)) {
      const merchantList = user.merchants;
      const merchantMap = {};

      var merchantNames = [];
      merchantList.forEach((merchant) => {
        var value = {};
        value.value = merchant.id;
        value.label = merchant.name;
        merchantNames.push(value);
        merchantMap[merchant.id] = merchant;
      });

      const merchantSel = useSelectState({
        value: merchant.id,
        label: merchant.name,
      });

      function useSelectState(initialValue) {
        const [value, setValue] = useState(initialValue);

        async function onChange(value) {
          setValue(value);

          if (value != null) {
            localStorage.setItem(
              "merchant",
              stringifyJson(merchantMap[value.value])
            );
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));

            trackUserLogin(userDetails.email, {
              email: userDetails.email,
              name: userDetails.name,
              userId: userDetails.id,
              merchant: merchantMap[value.value].name,
              createdTime: userDetails.created_on,
            });

            try {
              let merchantConfig = get(
                getMerchantOMConfigFromStorage(),
                `${value.value}`,
                null
              );
              if (isNull(merchantConfig)) {
                const tokenID = merchantMap[value.value].external_api_key;
                const request = await getMerchantOMConfig(tokenID);
                let data = await request.json();
                merchantConfig = data.data;
                const storageData = {
                  [merchantMap[value.value].id]: merchantConfig,
                };
                setMerchantOMConfig(storageData);
              }

              //   setOmEnabled(
              //     get(merchantConfig, "offerManagementConfig.isOMEnabled")
              //   );
            } catch (error) {
              //   setOmEnabled(false);
            }
            // change history over here
            // todo - configure this for all types of pages
            // - campaigns
            // - loyalty
            // todo - configure the history stack so that it works properly
            if (history.location.pathname.match(/journey\/creation\//g)) {
              history.replace("/journey/listing");
            }
            // why is this reload needed ?
            else window.location.reload();
            // this is needed when the merchant is changed but the page has to remain the same
            // this is due to the fact the localStorage changes cannot be read directly or made observable
            // todo - figure out a way to make merchant change observable to the entire component hierarchy
          }
        }
        function onFocus() {
          setValue(null);
        }
        return {
          value,
          onChange,
          onFocus,
        };
      }

      return (
        <UserLoggedInView
          userName={user.userName}
          merchant={merchant}
          merchantSel={merchantSel}
          merchantNames={merchantNames}
          Userlogout={() => Userlogout(history)}
          avatar={avatar1}
          modalChangePassword={modalChangePassword}
          setChangePassword={setChangePassword}
        />
      );
    }
    return null;
  }
);

export default UserProfile;
