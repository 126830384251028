const ServerUrl = {
  CDPURL: process.env.REACT_APP_CDP_SERVER_URL,
  CDP_PUI_URL: process.env.REACT_APP_CDP_PUI_URL,
  XENOURL: process.env.REACT_APP_XENO_BACKEND_URL,
  XENOSERVER: process.env.REACT_APP_XENO_URL,
  CAMPAIGNSERVER: process.env.REACT_APP_CAMPAIGNS_URL, // same as xenoserver in dev 
  LOCALSERVER: process.env.REACT_APP_XENO_LOCAL_URL,
  FLASKSERVER: process.env.REACT_APP_FLASK_URL,
  AIXENOSERVER: process.env.REACT_APP_AI_XENO_BACKEND_URL,
  XENOTESTURL: process.env.REACT_APP_XENO_TESTING_URL,
  XENONODEURL: process.env.REACT_APP_NODE_BACKEND_URL,
  MAGICAUTHFUNCTION: process.env.REACT_APP_MAGIC_AUTH_FUNCTION,
  XENOAUDIENCESERVER: process.env.REACT_APP_AUDIENCE_SERVER_URL,
  OFFER_MANAGEMENT_URL: process.env.REACT_APP_OFFER_MANAGEMENT_URL,
  GENAI_URL: process.env.REACT_APP_GENAI_URL,
};

export { ServerUrl };
