import { createStore } from "zustand";
import { devtools } from "zustand/middleware";

const globalContextStore = createStore(
  devtools(() => ({
    savedJourneyData: {},
  }))
);

export default globalContextStore;
