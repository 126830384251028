import "bootstrap-daterangepicker/daterangepicker.css";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import "react-sortable-tree/style.css";
import "./assets/base.scss";
import history from "./history";
import "./index.css";
import Main from "./main";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
const rootElement = document.getElementById("root");

Sentry.init({
  dsn: "https://d082da88ff374302af327224ee4cb771@o4504378047791104.ingest.sentry.io/4504378049036288",
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  whitelistUrls: [/ai.xeno\.in/],
  tracesSampleRate: 0.3,
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0,
});

const renderApp = (Component) => {
  ReactDOM.render(
    <Router history={history}>
      <Component />
    </Router>,
    rootElement
  );
};

renderApp(Main);

// if (module.hot) {
//     module.hot.accept('./DemoPages/Main', () => {
//         const NextApp = require('./DemoPages/Main').default;
//         renderApp(NextApp);
//     });
// }
serviceWorker.unregister();
// serviceWorker.register();
