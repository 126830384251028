import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { merchant } from "utils/functions/getStorageObject";
import { isRouteAccessible } from "../../routes/AccessLevelRoutes";
import isDataSet from "../../utils/functions/isDataSet";

export const NavbarItem = ({ navBarObj, navBarClicked, setNavBarClicked }) => {
  if (isDataSet(navBarObj)) {
    if (navBarObj.type === "dropdown") {
      return (
        <UncontrolledButtonDropdown
          nav
          inNavbar
          className={cx("", {
            highlight_nav_bar: navBarObj.data.some(
              (navObj) => navObj.label === navBarClicked
            ),
          })}
          id={navBarObj.label}
        >
          <DropdownToggle
            nav
            className={cx("nav-link-header", {
              "active-nav": navBarObj.data.some(
                (navObj) => navObj.label === navBarClicked
              ),
            })}
            id={navBarObj.label.toLowerCase()}
          >
            {navBarObj.label}
            <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown} />
          </DropdownToggle>
          <DropdownMenu className="rm-pointers">
            {navBarObj.data
              .filter((el) =>
                el.forMerchants ? el.forMerchants.includes(merchant().id) : true
              )
              .map((navObj) =>
                !isDataSet(navObj.accessLevel) ||
                isRouteAccessible({ ACCESS_LEVEL: navObj.accessLevel }) ? (
                  <DropdownItem
                    tag="a"
                    href={navObj.href}
                    key={navObj.label}
                    onClick={() => {
                      setNavBarClicked(navObj.label);
                    }}
                    target={navObj.target || "_self"} // Handle target attribute here
                    className={cx({
                      "active-nav": navObj.label === navBarClicked,
                    })}
                  >
                    {navObj.label}
                  </DropdownItem>
                ) : null
              )}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      );
    } else if (navBarObj.type === "link") {
      return isRouteAccessible({ ACCESS_LEVEL: navBarObj.accessLevel }) ? (
        <NavItem
          id={navBarObj.label}
          className={cx("", {
            highlight_nav_bar: navBarObj.label === navBarClicked,
          })}
        >
          <NavLink
            href={navBarObj.href}
            className={cx("nav-link-header", {
              "active-nav": navBarObj.label === navBarClicked,
            })}
            onClick={() => {
              setNavBarClicked(navBarObj.label);
            }}
            target={navBarObj.target || "_self"} // Handle target attribute here
          >
            {navBarObj.label}
          </NavLink>
        </NavItem>
      ) : null;
    }
  }
  return null;
};
