import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  TextField,
  OutlinedInput,
  InputAdornment,
  Icon,
  InputBase,
  styled,
  Typography,
} from "@mui/material";
// import TextInputField from "Components/Inputs/TextInputField";
import React from "react";

// todo - change border color of all the text fields and selects
// todo - add red color to the required * for all text fields and select
// todo - add mui based customer multi select

function GlobalMuiXenoTheme({ children }) {
  const theme = createTheme({
    components: {
      MuiRadio: {
        defaultProps: {
          size: "small",
          disableRipple: true,
          // icon: <BpIcon />,
          // checkedIcon: <BpCheckedIcon />,
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            borderRadius: 6,
            height: "75%",
            bottom: "15%",
            maxHeight: "36px",
            zIndex: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 50,
            textTransform: "capitalize",
            fontWeight: 600,
            color: "#C6C6C6",
            zIndex: 1,
            transition: "color 0.3s cubic-bezier(0.35, 0, 0.25, 1)",
            "&.Mui-selected": {
              zIndex: 1,
              color: "#fff",
            },
            "&.Mui-focusVisible": {
              color: "#fff",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            maxHeight: 20,
          },
          label: {
            fontSize: 12,
            fontWeight: 600,
            textTransform: "capitalize",
          },
          colorSecondary: {
            background: "#E0E3EB",
            color: "#3D445C",
          },
          colorSuccess: {
            background: "#ECFDF5",
            color: "#059669",
          },
          colorError: {
            background: "#FEF2F2",
            color: "#DC2626",
          },
          colorWarning: {
            background: "#ECFEFF",
            color: "#0891B2",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          checked: {
            backgroundColor: "#3AC47D",
            track: {
              backgroundColor: "#3AC47D",
            },
          },
          root: {
            height: "14px",
            width: "28px",
            padding: 0,
          },
          switchBase: {
            padding: "0",
            paddingTop: "2px",
            paddingLeft: "2px",
            paddingRight: "2px",
            "&.Mui-checked": {
              transform: "translateX(14px)",
              color: "white",
              "& + .MuiSwitch-track": {
                backgroundColor: "#3AC47D",
                opacity: 1,
              },
              "& + .MuiSwitch-thumb": {
                background: "white",
              },
            },
          },
          thumb: {
            height: "10px",
            width: "10px",
          },
          track: {
            background: "#FE6D6D",
            opacity: 1,
            "&:checked": {
              background: "#3AC47D",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            fontWeight: "600",
            boxShadow: "none",
            borderRadius: "6px",
            "&:hover": {
              boxShadow: "none",
              background: "inherit",
            },
          },
          outlined: {
            background: "white",
          },
          containedSecondary: {
            borderColor: "#E0E3EB",
            "&:hover": {
              background: "white",
            },
          },
          containedPrimary: {
            "&:hover": {
              background: "#005EEB",
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableElevation: true,
        },
      },
      // MuiRadio: {
      //   defaultProps: {
      //     disableElevation: true,
      //     disableRipple: true,
      //   },
      //   styleOverrides: {
      //     root: {
      //       borderColor: "#E0E3EB",
      //     },
      //   },
      // },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          tooltip: {
            padding: "1rem",
            background: "#373D3F",
          },

          arrow: {
            color: "#373D3F",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              top: "0px",
            },
            "& legend": {
              display: "none",
            },
          },
        },
      },

      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          size: "small",
          InputLabelProps: {
            shrink: true,
            disableAnimation: true,
            focused: false,
          },
        },
        styleOverrides: {
          root: {
            "& fieldset": {
              top: "0px",
            },
            "& legend": {
              display: "none",
            },
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          disableScrollLock: true,
        },
        styleOverrides: {
          list: {
            marginTop: 0,
            marginBottom: 0,
          },
          // paper: {
          //   boxShadow:
          //     "0px 1px 3px rgba(0,0,0,0.1), 0px 1px 2px rgba(0,0,0,0.06)!important",
          // },
        },
      },
      // MuiFormControlLabel: {
      //   styleOverrides: {
      //     fontWeight: 600,
      //   },
      //   defaultProps: {
      //     componentsProps: {
      //       typography: {
      //         variant: "capt",
      //       },
      //     },
      //   },
      // },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#3D445C",
            fontSize: "0.875rem", // same as subtitle2
            fontWeight: 600,
            "&.Mui-focused": {
              color: "#3D445C",
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          displayEmpty: true,
          // inputComponent: (props) => (
          //   <OutlinedInput
          //     endAdornment={
          //       <InputAdornment position="end">
          //         <Icon>
          //           <FaArrowCircleDown />
          //         </Icon>{" "}
          //       </InputAdornment>
          //     }
          //     onClick={props.onClick}
          //     onFocus={props.onFocus}
          //     type="select"
          //     placeholder={props.placeholder}
          //   />
          // ),
          // size: "small",
          // native: true,
          // input: <OutlinedInput sx={{ color: "#C6C6C6", fontSize: "12px" }} />,

          // renderValue: (selected) => {
          //   
          //   if (
          //     selected == null ||
          //     selected === undefined ||
          //     selected.length === 0
          //   )
          //     return <>test</>;
          //   // return selected.join(", ");
          // },
          // inputComponent: <TextField />,

          MenuProps: {
            PaperProps: {
              style: {
                boxShadow:
                  "0px 1px 3px rgba(0,0,0,0.1), 0px 1px 2px rgba(0,0,0,0.06)",
                border: "1px solid #E0E3EB",
                borderCollapse: "collapse",
              },
            },
            style: {
              boxShadow:
                "0px 1px 3px rgba(0,0,0,0.1), 0px 1px 2px rgba(0,0,0,0.06)",
              marginTop: "5px",
              borderRadius: "6px",
            },
            MenuListProps: {
              // sx: {
              //   "li:first-child": { display: "none" },
              // },
              style: {
                paddingTop: 5,
                paddingBottom: 5,
                "li:first-child": { display: "none" },
              },
            },
          },
        },
        styleOverrides: {
          root: {
            "& fieldset": {
              top: "0px",
            },
            "& legend": {
              display: "none",
            },
            "&>li:first-child": {
              display: "none",
            },
          },
        },
      },

      MuiMenuItem: {
        defaultProps: {
          // divider: true,
        },
        styleOverrides: {
          root: {
            fontSize: "14px",
            // border: "1px solid #E0E3EB",
            // borderCollapse: "collapse",
            padding: "8px 10px",
            maxHeight: "40px",
            color: "#3D445C",
            "&:first-child": {
              borderTop: "none",
            },
            "&:last-child": {
              borderBottom: "none",
            },
          },
        },
      },

      MuiInputBase: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            fontSize: "14px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            position: "relative",
            transform: "none",
            fontSize: "14px",
            color: "#3D445C",
          },
        },
      },
    },

    palette: {
      primary: {
        main: "#005EEB",
      },
      secondary: {
        main: "#fff",
        contrastText: "#3D445C",
      },
    },
    typography: {
      link: {
        fontSize: "10px",
        color: "#005EEB",
        cursor: "pointer",
      },
      link2: {
        fontSize: "0.75rem",
        textDecoration: "underline",
        color: "#005EEB",
        cursor: "pointer",
      },
      fontFamily: ['"Open Sans"', "sans-serif"].join(","),
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      subtitle2: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
      },
    },
  });

  return (
    <>
      {" "}
      <ThemeProvider theme={theme}>
        {" "}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
}

export default GlobalMuiXenoTheme;
