import { USER_LEVEL } from "../globalConstants";
import { getGMTDateFromUTC } from "./DateUtils";
import { merchant, user } from "./getStorageObject";
import isDataSet from "./isDataSet";

export function isAuthenticated() {
  if (
    isDataSet(user()) &&
    getGMTDateFromUTC(user().expiryTime, "YYYY-MM-DD HH:mm:SS").isAfter()
  ) {
    return true;
  }
  return false;
}
export function isCDPUser() {
  const merchantDetails = merchant();
  return (
    isDataSet(merchantDetails) &&
    isDataSet(merchantDetails.userMerchantMap) &&
    merchantDetails.userMerchantMap.permission_group === USER_LEVEL.CDP
  );
}

export function isStaffUser() {
  const merchantDetails = merchant();
  return (
    isDataSet(merchantDetails) &&
    isDataSet(merchantDetails.userMerchantMap) &&
    merchantDetails.userMerchantMap.permission_group === USER_LEVEL.STAFF
  );
}
