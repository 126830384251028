import React, { useState, useEffect } from "react";
import Loader from "react-loaders";

const ManagementKPIFabIndia = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false); // Since we're directly using the iframe, we can stop loading immediately.
    }, []);

    return (
        <div
            className="mt-4 mb-1"
            style={{
                height: "calc(100vh - 120px)",
            }}
            id="embeddingContainer"
        >
            {loading && (
                <div className="text-center">
                    <Loader type={"ball-pulse"} />
                    <h6>Loading Dashboard</h6>
                </div>
            )}
            {!loading && (
                <iframe
                    src="https://metabase.xeno.in/public/dashboard/17c76fb9-6f93-4793-a9e3-dbf9a5713fde"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allowTransparency
                    style={{ border: "none" }}
                ></iframe>
            )}
        </div>
    );
};

export default ManagementKPIFabIndia;
