import { isRouteAccessible } from "routes/AccessLevelRoutes";
import isDataSet from "utils/functions/isDataSet";
import { XENO_ROUTES } from "../../routes/RoutesUtil";
import { get } from "lodash";
import {
  merchant,
  getMerchantOMConfig as getMerchantOMConfigFromStorage,
} from "utils/functions/getStorageObject";
export const navBarData = [
  {
    type: "dropdown",
    label: "Customers",
    data: [
      {
        type: "link",
        label: "360 degree view",
        href: `#${XENO_ROUTES.SEARCH.PAGE_URL}`,
        accessLevel: XENO_ROUTES.SEARCH.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Segmentation",
        href: `#${XENO_ROUTES.SEGMENTATION.PAGE_URL}`,
        accessLevel: XENO_ROUTES.SEGMENTATION.ACCESS_LEVEL,
      },
    ],
  },
  {
    type: "dropdown",
    label: "Campaigns",
    data: [
      {
        type: "link",
        label: "Journey Builder",
        href: `#${XENO_ROUTES.JOURNEY.PAGE_URL}/listing`,
        accessLevel: XENO_ROUTES.JOURNEY.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Campaign listing",
        href: `#${XENO_ROUTES.CAMPAIGN.PAGE_URL}/listing`,
        accessLevel: XENO_ROUTES.CAMPAIGN.ACCESS_LEVEL,
      },
    ],
  },
  // {
  //   type: "dropdown",
  //   label: "Personas",
  //   data: [
  //     {
  //       type: "link",
  //       label: "Persona insights",
  //       href: "#/personaInsights",
  //     },
  //   ],
  // },
  {
    type: "dropdown",
    label: "Insights",
    data: [
      {
        type: "link",
        label: "Persona Insights",
        href: `#${XENO_ROUTES.PERSONA_INSIGHTS.PAGE_URL}/v2`,
        accessLevel: XENO_ROUTES.PERSONA_INSIGHTS.ACCESS_LEVEL,
        //href: "#/personaInsights",
      },
      {
        type: "link",
        label: "Business Reports",
        href: `#${XENO_ROUTES.BUSINESSREPORT.PAGE_URL}`,
        accessLevel: XENO_ROUTES.BUSINESSREPORT.ACCESS_LEVEL,
        //href: "#/business-reports",
      },
      {
        type: "link",
        label: "Business Dashboard",
        href: `#${XENO_ROUTES.BUSINESSDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.BUSINESSDASHBOARD.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Campaign Dashboard",
        href: `#${XENO_ROUTES.CAMPAIGNDASHBOARD.PAGE_URL}/v1`,
        accessLevel: XENO_ROUTES.CAMPAIGNDASHBOARD.ACCESS_LEVEL,
        // href: "#/campaignDashboard",
      },
      {
        type: "link",
        label: "Campaign Dashboard V2",
        href: `#${XENO_ROUTES.CAMPAIGNDASHBOARDV2.PAGE_URL}`,
        accessLevel: XENO_ROUTES.CAMPAIGNDASHBOARDV2.ACCESS_LEVEL,
        forMerchants: [120, 1683]
      },
      // {
      //   type: "link",
      //   label: "Fabindia Dashboard",
      //   href: `#${XENO_ROUTES.FABINDIADASHBOARD.PAGE_URL}`,
      //   accessLevel: XENO_ROUTES.FABINDIADASHBOARD.ACCESS_LEVEL,
      //   forMerchants: [120, 1818]
      //   // href: "#/campaignDashboard",
      // },

      

      {
        type: "link",
        label: "Fraud Report Dashboard",
        href: `#${XENO_ROUTES.SOCIALFRAUDDETECTIONDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.SOCIALFRAUDDETECTIONDASHBOARD.ACCESS_LEVEL,
        forMerchants: [120, 1690],
        // href: "#/campaignDashboard",
      },

      {
        type: "link",
        label: "Coupon Redemption Dashboard",
        href: `#${XENO_ROUTES.BEINGHUMANCOUPONREDEMPTIONDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.BEINGHUMANCOUPONREDEMPTIONDASHBOARD.ACCESS_LEVEL,
        forMerchants: [120, 1779],
        // href: "#/campaignDashboard",

      },
      {
        type: "link",
        label: "Campaign Hit Rate",
        href: `#${XENO_ROUTES.HITRATEDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.HITRATEDASHBOARD.ACCESS_LEVEL,
        forMerchants: [1683],
        // href: "#/campaignDashboard",
      },
      {
        type: "link",
        label: "Loyalty Dashboard",
        href: `#${XENO_ROUTES.LOYALTYDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.LOYALTYDASHBOARD.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Order Details Dashboard",
        href: `#${XENO_ROUTES.ORDERDETAILSDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.ORDERDETAILSDASHBOARD.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Customer Details Dashboard",
        href: `#${XENO_ROUTES.CUSTOMERDETAILSDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.CUSTOMERDETAILSDASHBOARD.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Lal's dashboard",
        href: `#${XENO_ROUTES.MYDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.MYDASHBOARD.ACCESS_LEVEL,
        forMerchants: [1664],
        // href: "#/campaignDashboard",
      },
      {
        type: "link",
        label: "WWWI Analysis Dashboard",
        href: `#${XENO_ROUTES.WWIANALYSISDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.WWIANALYSISDASHBOARD.ACCESS_LEVEL,
        // href: "https://metabase.xeno.in/public/dashboard/4117ae2e-e865-4473-bf46-afb0ff46afdf",
        forMerchants: [1818, 120],
        // target : "_blank"
        // href: "#/campaignDashboard",
      },
      {
        type: "link",
        label: "Tier Overview Dashboard",
        href: `#${XENO_ROUTES.TIEROVERVIEWDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.TIEROVERVIEWDASHBOARD.ACCESS_LEVEL,
        // href: "https://metabase.xeno.in/public/dashboard/cdee7155-707e-4fa9-a60d-31d71ff44992",
        forMerchants: [1818, 120],
        // target : "_blank"
        
        // href: "#/campaignDashboard",
      },
      {
        type: "link",
        label: "Analytics KPI Dashboard",
        href: `#${XENO_ROUTES.MANAGEMENTKPIREPORT.PAGE_URL}`,
        accessLevel: XENO_ROUTES.MANAGEMENTKPIREPORT.ACCESS_LEVEL,
        // forMerchants: [120, 1818],
        // href: "https://metabase.xeno.in/public/dashboard/8ef1688b-e0c3-4b3e-b8cb-1d7779fe405e",
        forMerchants: [1818, 120],
        // target : "_blank"
        // href: "#/campaignDashboard",
      },

      {
        type: "link",
        label: "Management KPI Dashboard",
        href: `#${XENO_ROUTES.ANALYTICKPIREPORT.PAGE_URL}`,
        accessLevel: XENO_ROUTES.ANALYTICKPIREPORT.ACCESS_LEVEL,
        forMerchants: [120, 1818],
        // href: "https://metabase.xeno.in/public/dashboard/17c76fb9-6f93-4793-a9e3-dbf9a5713fde",
        // forMerchants: [1818, 120],
        // target : "_blank"
        // href: "#/campaignDashboard

      },

      {
        type: "link",
        label: "Points Expiry Dashboard",
        href: `#${XENO_ROUTES.POINTSEXPIRYREPORT.PAGE_URL}`,
        accessLevel: XENO_ROUTES.POINTSEXPIRYREPORT.ACCESS_LEVEL,
        forMerchants: [120, 1818],
        // href: "#/campaignDashboard
        

      },
    ],
  },
  {
    type: "link",
    label: "Loyalty",
    href: `#${XENO_ROUTES.LOYALTY.PAGE_URL}`,
    accessLevel: XENO_ROUTES.LOYALTY.ACCESS_LEVEL,
    
    // href: "#/loyalty",
  },

  
  // todo !! important convert this to global store
  {
    enabled: get(
      get(getMerchantOMConfigFromStorage(), `${get(merchant(), "id")}`, null),
      "offerManagementConfig.isOMEnabled",
      false
    ),
    type: "link",
    label: "Offers",
    href: `#${XENO_ROUTES.OFFERS.PAGE_URL}`,
    accessLevel: XENO_ROUTES.OFFERS.ACCESS_LEVEL,
    // href: "#/loyalty",
  },

  {
    type: "dropdown",
    label: "Tools",
    data: [
      {
        type: "link",
        label: "Merchant SetUp",
        href: `#${XENO_ROUTES.SETUP.PAGE_URL}`,
        accessLevel: XENO_ROUTES.SETUP.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "Loyalty Utilities",
        href: `#${XENO_ROUTES.UTILITIES.PAGE_URL}`,
        accessLevel: XENO_ROUTES.UTILITIES.ACCESS_LEVEL,
      },
      {
        type: "link",
        label: "OTP Dashboard",
        href: `#${XENO_ROUTES.OTPDASHBOARD.PAGE_URL}`,
        accessLevel: XENO_ROUTES.OTPDASHBOARD.ACCESS_LEVEL,
      },
    ],
  },
];
export function showNavBarObj(navBarObj) {
  let value = isShowNavBar(navBarObj);
  if (!isDataSet(value)) {
    return true;
  }
  return value;
}
function isShowNavBar(navBar) {
  let val = true;

  if (isDataSet(navBar) && isDataSet(navBar.data)) {
    val = navBar.data.some((navObj) =>
      isRouteAccessible({ ACCESS_LEVEL: navObj.accessLevel })
    );

    return val;
  }
}
