import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { sub } from "date-fns";
import Loader from "react-loaders";
import { Row, Col, InputGroup } from "reactstrap";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { merchant } from "utils/functions/getStorageObject";
import { getMyDashboardEmbedUrl } from "../../../Api/index";

const MyDashboard = () => {
    const dashboardContainer = useRef(null);

    const today = new Date();
    const [dashboard, setDashboard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(
        sub(today, {
            months: 3,
        })
    );
    const [endDate, setEndDate] = useState(today);
    function handleError(payload) {
        alert("Failed to load dashboard. Error: ", payload);

    }
    function handleOnLoad(payload) {
        setLoading(false);
    }
    function handleDateRangeSelect(evnt, picker) {
        // console.log(evnt, picker);
        const startDate = picker.startDate.format("YYYY-MM-DD");
        const endDate = picker.endDate.format("YYYY-MM-DD");
        setStartDate(startDate);
        setEndDate(endDate);
        dashboard.setParameters({
            StoreName: 'All',
            State: 'Dubai',
            Country: 'All',
            StartDate: startDate,
            EndDate: endDate,
        });
    }
    useEffect(() => {

        getMyDashboardEmbedUrl()
            .then((res) => res.text())
            .then((data) => {
                if (dashboardContainer.current) {
                    const options = {
                        url: data,
                        container: document.querySelector("#embeddingContainer"),
                        parameters: {
                            StoreName: 'All',
                            State: 'Dubai',
                            Country: 'All',
                            StartDate: startDate,
                            EndDate: endDate,
                        },
                        scrolling: "no",
                        height: "100%",
                        width: "100%",
                        locale: "en-US",
                        footerPaddingEnabled: true,
                    };
                    const dashboard = QuickSightEmbedding.embedDashboard(options);
                    dashboard.on("error", handleError);
                    dashboard.on("load", handleOnLoad);
                    setLoading(false);
                    setDashboard(dashboard);
                }
            })
            .catch(console.error);
    }, [dashboardContainer]);

    return (
        <>
            <Row className="mt-3 mb-1">
                <Col sm={3}>
                    <h5>Lal's dashboard</h5>
                </Col>
            </Row>
            <div
                ref={dashboardContainer}
                style={{
                    height: "calc(100vh - 120px)",
                }}
                id="embeddingContainer"
            >
                {loading && (
                    <div className="text-center">
                        <Loader type={"ball-pulse"} />
                        <h6>Loading Dashboard</h6>
                    </div>
                )}
            </div>
        </>
    );
};

export default MyDashboard;
