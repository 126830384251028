import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import isDataSet from "../../utils/functions/isDataSet";

const animatedComponents = makeAnimated();

export default function SelectUtil(props) {
  const {
    options,
    setFilter,
    filter,
    defaultValue,
    isMulti = true,
    placeholder = "Select...",
    clearable = true,
  } = props;

  function onSelectChange(event) {
    if (isMulti) {
      let arr = [];
      if (isDataSet(event)) {
        const lastEvent = event[event.length - 1];
        const allSelected = lastEvent && parseInt(lastEvent.id) === 0;
        if (allSelected) {
          arr = [defaultValue];
        } else {
          arr = event
            .filter((element) => parseInt(element.id) !== 0)
            .map((element) => ({
              id: element.id,
              label: element.label,
              value: element.id,
            }));
        }
      }
      setFilter(arr);
    } else {
      setFilter(event);
    }
  }

  const colourStyles = {
    option: (styles, { data }) => ({
      ...styles,
      color: data.color ? data.color : "#000000",
    }),
    singleValue: (styles) => ({ ...styles }),
  };

  if (isDataSet(options) && options.length > 0) {
    return (
      <Select
        value={filter}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        options={options}
        isSearchable
        components={animatedComponents}
        getOptionValue={(element) => element.id}
        onChange={onSelectChange}
        styles={colourStyles}
        placeholder={placeholder}
        isClearable={clearable}
      />
    );
  }

  return null;
}
