export const MAX_DATA_ROWS = 5;

export const USER_LEVEL = {
  STAFF: "staff",
  SUPER: "super",
  ACTUALS: "actuals",
  CHECKIN: "checkin",
  CDP: "cdp",
  CUSTOMER_AND_INSIGHTS: "customer_and_insights",
};
