import { merchant } from "./getStorageObject";
import isDataSet from "./isDataSet";

export function isDemoAccount() {
	const merch = merchant();
	return isDataSet(merch) && parseInt(merch.sandboxAccount) === 2;
}

export const isForeignMerchant = () => {
	const merch = merchant();
	return isDataSet(merch) && merchant().country_code == 0;
};
