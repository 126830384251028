import React from "react";
import { Snackbar } from "@mui/material";
import { get } from "lodash";
import useGlobalStore from "stores/_globalStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export default function NotificationSnackbar() {
  const { listingNotification } = useGlobalStore((state) => ({
    listingNotification: state.listingNotification,
  }));
  return (
    <Snackbar
      open={listingNotification.status}
      autoHideDuration={
        get(listingNotification, "time", "s") === "s" ? 2500 : 4500
      }
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={listingNotification.closeNotification}
    >
      <div
        className="p-4"
        style={{
          borderRadius: 6,
          width: 740,
          marginTop: 70,
          background:
            listingNotification.type === "error" ? "#FE6D6D" : "#ECFDF5",
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          className="mb-0 pb-0"
          style={{
            color: listingNotification.type === "error" ? "white" : "#047857",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {/* <img className="mr-2" src={success} width="18" height="18" /> */}
          <FontAwesomeIcon
            style={{
              color: listingNotification.type === "error" ? "white" : "#047857",
            }}
            className="mr-2"
            icon={
              listingNotification.type === "error"
                ? faExclamationCircle
                : faCheckCircle
            }
          />
          <b>{listingNotification.message}</b>

          {/* <b>Goals</b> */}
        </p>
        <FontAwesomeIcon
          onClick={listingNotification.closeNotification}
          className="mr-2"
          style={{
            cursor: "pointer",
            color: listingNotification.type === "error" ? "white" : "#047857",
          }}
          icon={faTimes}
        />
      </div>
    </Snackbar>
  );
}
