import React, { useState, useEffect } from "react";
import { Nav } from "reactstrap";
import { merchant } from "utils/functions/getStorageObject";
import isDataSet from "utils/functions/isDataSet";
import { navBarData, showNavBarObj } from "./NavBarUtils";
import { NavbarItem } from "./NavbarView";
import { getMerchantOMConfig as getMerchantOMConfigFromStorage } from "utils/functions/getStorageObject";
import { getMerchantOMConfig } from "Api";
import { setMerchantOMConfig } from "utils/functions/setLocalStorageObject";
import { get, isNull } from "lodash";

const XenoNavbar = () => {
  const [navBarClicked, setNavBarClicked] = useState(null);
  const [omEnabled, setOmEnabled] = useState(false);

  async function checkOMConfig() {
    try {
      let merchantConfig = get(
        getMerchantOMConfigFromStorage(),
        `${merchant().id}`,
        null
      );
      // debugger;
      if (isNull(merchantConfig)) {
        const tokenID = merchant().external_api_key;
        const request = await getMerchantOMConfig(tokenID);
        let data = await request.json();
        merchantConfig = data.data;
        const storageData = {
          [merchant().id]: merchantConfig,
        };
        if (merchantConfig.offerManagementConfig.isOMEnabled) {
          setOmEnabled(true);
        }
        setMerchantOMConfig(storageData);
      } else {
        if (merchantConfig.offerManagementConfig.isOMEnabled) {
          setOmEnabled(true);
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    // debugger;
    checkOMConfig();
  }, []);
  return (
    <Nav className="mr-auto" navbar>
      {navBarData.map((navBarObj, index) =>
        ((isDataSet(navBarObj) && get(navBarObj, "enabled", true)) ||
          (navBarObj.label == "Offers" && omEnabled)) &&
        showNavBarObj(navBarObj) ? (
          <NavbarItem
            navBarObj={navBarObj}
            key={index}
            navBarClicked={navBarClicked}
            setNavBarClicked={setNavBarClicked}
          />
        ) : null
      )}
    </Nav>
  );
};

export default XenoNavbar;
