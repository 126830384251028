import React from "react";
import { Row, Col } from "reactstrap";

const ErrorBoundary = ({ error, componentStack }) => {
  // clear storage since the issue can re occur due to faulty data in browser storage
  sessionStorage.clear();
  localStorage.clear();
  return (
    <Row className="align-items-center h-100 text-center">
      <Col>
        <h1>Oh..Looks like an error occured.</h1> <p>Issue has been reported</p>{" "}
      </Col>
    </Row>
  );
};

export default ErrorBoundary;
