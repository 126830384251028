import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { merchant } from "./getStorageObject";
import isDataSet from "./isDataSet";
import { isForeignMerchant } from "./MerchantUtils";
import { isValidPhoneNumber } from "react-phone-number-input";

export function getCorrectDate(date) {
  return moment(date).subtract(5, "hours").subtract(30, "minutes");
}

export function isDemoAccount() {
  const merch = merchant();
  return isDataSet(merch) && parseInt(merch.sandboxAccount) === 2;
}

export function returnCorrectTimeStampFromGMTTimeStamp(timeStamp) {
  let date = new Date(timeStamp);
  if (moment(date).isValid()) {
    let localOffset = -1 * date.getTimezoneOffset() * 60000;
    return date.getTime() - localOffset;
  }
  return 0;
}
// return a random number between min and max range
export function generateRandom(min = 0, max = 100) {
  let difference = max - min;
  let rand = Math.random();
  rand = Math.floor(rand * difference);
  rand = rand + min;
  return rand;
}

export function returnDateFromGMTTimeStamp(timeStamp) {
  if (isDataSet(timeStamp)) {
    const timeStamp2 = returnCorrectTimeStampFromGMTTimeStamp(timeStamp);
    return new Date(timeStamp2);

    // return new Date();
  }
  // return new Date();
}

export function returnTimeFromGMTTimeStamp(timeStamp) {
  if (isDataSet(timeStamp)) {
    const timeStamp2 = returnCorrectTimeStampFromGMTTimeStamp(timeStamp);
    const time = new Date(timeStamp2);
    return `${time.getHours()}:${time.getMinutes()} ${time
      .toLocaleTimeString()
      .split(" ")
      .slice(-1)}`;

    // return new Date();
  }
  // return new Date();
}

export function returnGMTTimeStamp(date) {
  if (isDataSet(date) && moment(date).isValid()) {
    return new Date(
      date.getTime() + -1 * date.getTimezoneOffset() * 60000
    ).getTime();
  } else return NaN;
}

export function returnGMTTimeStampFromDateString(date) {
  if (isDataSet(date) && moment(date).isValid()) {
    date = new Date(date);
    const test = new Date(
      date.getTime() + -1 * date.getTimezoneOffset() * 60000
    ).getTime();
    return test;
  } else return NaN;
}

export function returnStartofDayGMTTimeStamp(date) {
  if (isDataSet(date) && moment(date).isValid()) {
    date = new Date(moment(date).startOf("day"));
    return new Date(
      date.getTime() + -1 * date.getTimezoneOffset() * 60000
    ).getTime();
  } else return NaN;
}

export function returnEndofDayGMTTimeStamp(date) {
  if (isDataSet(date) && moment(date).isValid()) {
    date = new Date(moment(date).endOf("day"));
    return new Date(
      date.getTime() + -1 * date.getTimezoneOffset() * 60000
    ).getTime();
  } else return NaN;
}

export function isEmpty(str) {
  if (isDataSet(str) && typeof str === "string" && str.trim() !== "") {
    return false;
  }
  return true;
}

export function isValidUrl(url) {
  if (isDataSet(url)) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(url);
  }
  return false;
}

export function isValidUrlorPhone(url) {
  const isURL = isValidUrl(url);
  const isPhone = isValidPhoneNumber(url);
  return (isURL || isPhone) && !isEmpty(url);
}

export function stringTrim(str) {
  if (isDataSet(str)) {
    return str.trim();
  }
  return "";
}

export function totalSpent(amount) {
  let mod;
  if (amount > 100000) {
    mod = (parseFloat(amount) / 100000).toFixed(1);

    return mod + "L";
  } else if (amount > 1000) {
    mod = (parseFloat(amount) / 1000).toFixed(1);

    return mod + "k";
  }
  return amount;
}

export function formatMoney(amount) {
  if (isDataSet(amount) && !isNaN(amount)) {
    amount = amount.toString();
    var lastThree = amount.substring(amount.length - 3);
    var otherNumbers = amount.substring(0, amount.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let regexString = isForeignMerchant()
      ? /\B(?=(\d{3})+(?!\d))/g
      : /\B(?=(\d{2})+(?!\d))/g;
    var res = otherNumbers.replace(regexString, ",") + lastThree;
    return res;
  }
  return amount;
}

export function removeDuplicateFromArray(array) {
  if (isDataSet(array) && array.length > 0) {
    const tempArr = cloneDeep(array);
    return Array.from(new Set(tempArr));
  }
}
