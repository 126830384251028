import { Magic, SDKError, RPCError, RPCErrorCode } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";

let m = new Magic(process.env.REACT_APP_MAGIC_PUBLIC_KEY, {
  extensions: [new OAuthExtension()],
}); // todo - refactor to a util component

const loginWithEmail = async (email) => {
  try {
    await m.auth.loginWithMagicLink({ email: email });
    const isLoggedIn = await m.user.isLoggedIn();

    const idToken = await m.user.getIdToken();
    localStorage.setItem("didToken", idToken);
    return isLoggedIn;
  } catch (error) {
    handleMagicError(error);
  }
};

const loginWithGoogle = async () => {
  try {
    const googleLogin = await m.oauth.loginWithRedirect({
      provider: "google",
      redirectURI:
        window.location.origin + process.env.REACT_APP_GOOGLE_AUTH_CALLBACK,
    });
    return googleLogin;
  } catch (error) {
    handleMagicError(error);
  }
};

const loginWithMicrosoft = async () => {
  try {
    const googleLogin = await m.oauth.loginWithRedirect({
      provider: "microsoft",
      redirectURI:
        window.location.origin + process.env.REACT_APP_GOOGLE_AUTH_CALLBACK,
      // scope:['user:email']
    });
    return googleLogin;
  } catch (error) {
    handleMagicError(error);
  }
};

const handleGoogleLoginCallback = async () => {
  try {
    const result = await m.oauth.getRedirectResult();
    const idToken = result.magic.idToken;
    localStorage.setItem("didToken", idToken);
    return result.magic.userMetadata.email;
  } catch (error) {
    handleMagicError(error);
  }
};

const handleMagicError = (error) => {
  // todo - handle specific error codes
  // todo - 1. Magic App Limit Reached - add email notification in case of limit reached
  if (error instanceof SDKError) {
  }
  if (error instanceof RPCError) {
    if (error.code === RPCErrorCode.UserRequestEditEmail) {
      throw new Error("Email Edited");
    } else if (
      error.code === RPCErrorCode.AccessDeniedToUser ||
      error.code === RPCErrorCode.InternalError
    ) {
      throw new Error("Access Denied");
    }
  }
  throw error;
};

export {
  loginWithEmail,
  m,
  loginWithGoogle,
  handleGoogleLoginCallback,
  loginWithMicrosoft,
};
