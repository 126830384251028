import { isEmpty } from "../utils/functions/generalUtils";
import { merchant, user } from "../utils/functions/getStorageObject";
import isDataSet from "../utils/functions/isDataSet";
import {
    _convertObjectToQueryParam,
    _makeGetRequest,
    _makeGetRequestWithHeader,
    _makeLoginPostRequest,
    _makeMagicAuthLoginRequest,
    _makePostRequest,
    _makePostRequestV2,
    _makePostRequestWithHeader,
    _makePostRequestWithHeaderAndSignal,
    _makePostRequestWithHeaderFormData,
    _makePutRequestWithHeader,
    _makeRequestWithHeader
} from "./apiHelpers";
import { ServerUrl } from "./ServerUrlEnum";

const customerUrl = "customer/";
const merchantUrl = "merchant/";

export const getOrderData = (data = null) => {
    return _makeGetRequest(`${ServerUrl.CDPURL}${customerUrl}getOrders`, data);
};

export const getuserProfile = (data = null) => {
    return _makeGetRequest(`${ServerUrl.CDPURL}${customerUrl}getProfile`, data);
};

export const getUserProfileV3ByEmailOrPhone = (data = null, merchantId = null) => {
    // request body (either of any ) => { "phone": "","email": "abc@email.in","customerId": 0}

    let url = `${ServerUrl.CDPURL}${customerUrl}getProfile/v3`;
    return _makePostRequestV2(url, data, { "merchantId": merchantId });
};

export const getuserProfilev2 = (data = null) => {
    return _makeGetRequest(
        `${ServerUrl.CDPURL}${customerUrl}getProfile/v2`,
        data
    );
};

export const getLoyality = (data = null) => {
    return _makeGetRequest(`${ServerUrl.CDPURL}${customerUrl}getLoyalty`, data);
};

export const getPersonaInsights = (data = null) => {
    return _makeGetRequest(
        `${ServerUrl.CDPURL}${merchantUrl}getPersonaInsights`,
        data
    );
};

export const getPersonaInsightsv2 = (data = null) => {
    return _makeGetRequest(
        `${ServerUrl.CDPURL}${merchantUrl}getPersonaInsights/v2`,
        data
    );
};

export const getPersonaInsightsDropDownData = (data = null) => {
    return _makeGetRequest(
        `${ServerUrl.CDPURL}${merchantUrl}get_dropdown_data`,
        data
    );
};

export const getCampaigns = (data = null) => {
    return _makeGetRequest(`${ServerUrl.CDPURL}${customerUrl}getCampaign`, data);
};

export const userLogin = (postData = null) => {
    const url = "api/xeno/login/loginWithPassword";

    return _makeLoginPostRequest(`${ServerUrl.CDP_PUI_URL}${url}`, postData);
};

export const magiAuthLogin = (data = null) => {
    const url = "api/xeno/login/loginWithDidToken";
    return _makeMagicAuthLoginRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getCampaignDetail = (data = null) => {
    const url = "api/xeno/pui/masterCampaign/getCampaignAnalytics/meta";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getCampaignOrder = (data = null) => {
    const url = "api/xeno/pui/masterCampaign/getCampaignAnalytics/orders";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getCampaignAudience = (data = null) => {
    const url = "api/xeno/pui/masterCampaign/getCampaignAnalytics/convertedcustomers";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getFacebookPreview = (data = null) => {
    const url = "api/xeno/campaigns/getDataForApproval";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getCampaignListing = async (data = null) => {
    const url = "api/xeno/pui/masterCampaign/ai/getCampaignData";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const removeCustomerFromSegment = (data = null) => {
    const url = "api/xeno/filters/removeManualCustomer";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getUserDetails = (userId) => {
    try {
        const url = `api/xeno/users/userdetail/${userId}`;
        return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, null);
    } catch (err) {
    }
};

export const getCampaignPrefillData = (data = null) => {
    const url = "api/xeno/pui/masterCampaign/getMasterCampaignData";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getSplitAudience = (data = null) => {
    const url = "api/xeno/masterCampaign/getSplitAudience";
    return _makeGetRequest(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const createPersonalizedCampaign = (data = null) => {
    const url = "api/xeno/pui/campaignStaging/createCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getCreateCampaignData = (data = null) => {
    const url = "api/xeno/campaignStaging/create";
    return _makeGetRequest(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const updatePersonalizedCampaign = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/campaignStaging/saveCampaign" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, postData);
};

export const saveFbContent = (data) => {
    const url = "api/xeno/pui/fbcampaigns/saveFBContent";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getFbPreview = (data) => {
    const url = "api/xeno/pui/masterCampaign/xpFbCampaignPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getContentFbPreview = (data) => {
    const url = "api/xeno/pui/content/xpFbPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getPersonalizedFbPreview = (data) => {
    const url = "api/xeno/pui/campaignStaging/getXPFbAdvPreviews";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const uploadImageToServer = (file) => {
    var data = new FormData();
    data.append("file", file);
    const url = "api/xeno/uploads/uploadFileToS3";
    // * hardcoded as this api works in ai backend
    return fetch(`${ServerUrl.XENOSERVER}${url}`, {
        method: "POST",
        body: data,
    });
};
export const uploadImageToServerEmail = (file) => {
    var data = new FormData();
    data.append("file", file);
    const url = `api/xeno/uploads/uploadHTMLToS3`;

    return fetch(`${ServerUrl.XENOSERVER}${url}`, {
        method: "POST",
        body: data,
    });
};

export const submitPersonalizedCampaign = (data) => {
    const url = "api/xeno/pui/campaignStaging/submitCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const updatePersonalizedCampaignData = (data) => {
    const url = "api/xeno/masterCampaign/update_data";
    return _makeGetRequest(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getVariationFilter = (data) => {
    const url = "api/xeno/pui/campaignStaging/getPersonas";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getVariationsAudienceCount = (data) => {
    const url = "api/xeno/pui/campaignStaging/getPersonalizedCustomerCount";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOAUDIENCESERVER}${url}`, data);
};

export const createNewOffer = (data) => {
    const url = "api/xeno/pui/rewards/createReward";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getAllFilters = (data) => {
    const url = "api/xeno/filters/getAllFilters";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getCampaignApprovalPreview = (data) => {
    const url = "api/xeno/pui/campaignStaging/getCampaignPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const approvePersonalizedCampaign = (data) => {
    const url = "api/xeno/pui/campaigns/approveCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getEditCampaignData = (data) => {
    const url = "api/xeno/pui/campaignStaging/editCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getFbCotentData = (data) => {
    const url = "api/xeno/pui/fbcampaigns/getFBContent";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getDuplicateCampaignData = (data) => {
    const url = "api/xeno/pui/campaignStaging/duplicateCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const retriggerPersonalizedCampaign = (data) => {
    const url = "api/xeno/pui/campaignStaging/re_trigger_campaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const deletePersonalizedCampaign = (data) => {
    const url = "api/xeno/pui/campaignStaging/deleteCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const playPersonalizedCampaign = (data = null) => {
    const url = "api/xeno/pui/campaignStaging/resumeCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};
export const pausePersonalizedCampaign = (data = null) => {
    const url = "api/xeno/pui/campaignStaging/pauseCampaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const updateSMSData = async () => {
    await updateCommLogAndSummaryTables();
};

export const updateEmailData = async (data) => {
    const url = "api/xeno/pui/campaigns/syncEmailCampaignAnalytics";
    await Promise.all([
        updateCommLogAndSummaryTables(),
        _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data),
    ]);
};

export const updateWhatsAppData = async () => {
    await updateCommLogAndSummaryTables();
};

export const updateCommLogAndSummaryTables = async () => {
    console.log("updating commlog and summary tables");
    const summaryUpdaterLambdaUrl =
        "https://qeqrdyc2ja4idc2rihgzhyo4qy0atfdx.lambda-url.ap-south-1.on.aws/"; //lambda: whatsapp-campaign-summary-updater-cron
    const commLogUpdaterLambdaUrl =
        "https://cxico5ovv5gxjfre7nkxsgbxxm0yqmoz.lambda-url.ap-south-1.on.aws/"; //lambda: comm-log-updater
    await Promise.all([
        _makeGetRequest(summaryUpdaterLambdaUrl),
        _makeGetRequest(commLogUpdaterLambdaUrl),
    ]);
};

export const getActivePersonas = (data) => {
    const url = "api/xeno/personas/getActivePersonas";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getUnusedPersonas = (data) => {
    const url = "api/xeno/personas/getUnusedPersonas";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const savePersonaConfiguration = (data) => {
    const url = "api/xeno/personas/savePersonaConfiguration";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const updatePersona = (data) => {
    const url = "updatePersona";
    return _makeGetRequest(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const deletePersonaConfiguration = (data) => {
    const url = "api/xeno/personas/deletePersonaConfiguration";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const updateAllPersonas = (data) => {
    const url = "updateAllPersonaForMerchant";
    return _makeGetRequest(`${ServerUrl.FLASKSERVER}${url}`, data);
};

// export const getPersonaData = (data) => {
//   const url =
//     "api/xeno/masterCampaign/getCampaignAnalytics/getPersonalisedStats";
//   return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
// };

export const validateCampaign = (data) => {
    const url = "api/xeno/pui/masterCampaign/valid_campaign";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const updatePersonaConfig = (data) => {
    const url = "updatePersonaConfiguration";
    return _makeGetRequest(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getPersonaConfig = (data) => {
    const url = "getPersonaConfiguration";
    return _makeGetRequest(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getAutomatedAudienceDataApi = (data) => {
    const url = "api/xeno/pui/masterCampaign/automated_segment";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getAutomatedAudienceInsights = (data) => {
    const url = "getAutomatedAudience";
    return _makeGetRequest(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getPrefillPersonalizedCampaignData = (data) => {
    const url = "api/xeno/pui/campaignStaging/getCreationData";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getPrefillPersonalizedCampaignDataOm = (data) => {
    const url = "api/xeno/pui/campaignStaging/getCreationData";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getPrefillPersonalizedJourneyData = (data) => {
    const url = "api/xeno/pui/journey/getCreationData";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getLoyaltyData = (data) => {
    let url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_loyalty_setup`;
    return _makeGetRequestWithHeader(url, data);
};

export const getLoyaltySetupData = (data) => {
    let url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/loyalty_rule_data`;
    return _makeGetRequestWithHeader(url, data);
    // return _makePostRequestWithHeader(url, data);
};

export const getOTPConfigurationData = (data) => {
    let url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_otp_config`;
    return _makeGetRequestWithHeader(url, data);
    // return _makePostRequestWithHeader(url, data);
};

export const getTierData = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_tier_data`;
    return _makeGetRequestWithHeader(url, data);
};

export const getPromotionData = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_promo_data`;
    return _makeGetRequestWithHeader(url, data);
};
export const getReferralData = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_referral_data`;
    return _makeGetRequestWithHeader(url, data);
};
export const getContestData = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_contest_data`;
    return _makeGetRequestWithHeader(url, data);
};

export const updateLoyaltyData = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url =
        `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/save_action` + queryParam;

    return _makePostRequestWithHeader(url, postData);
};

export const updateLoyaltySetupData = (data, merchantID, userID) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/merchant_loyalty_setup?merchantId=${merchantID}&userId=${userID}`;
    return _makePostRequestWithHeader(url, data);
};

export const toggleReferralSetupData = (merchantID) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/referral/toggle?merchantId=${merchantID}`;
    return _makePostRequestWithHeader(url);
};

export const updateReferralSetupData = (data, merchantID) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/referral/setup?merchantId=${merchantID}`;
    return _makePostRequestWithHeader(url, data);
};

export const updateLoyaltyRank = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/update_tier_rank`;
    return _makeGetRequestWithHeader(url, data);
};

export const updateEarningRuleRank = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/update_earning_rule_rank?merchantId=${data.merchantId}&tierId=${data.tierId}&ruleId=${data.ruleId}&ruleRank=${data.ruleRank}`;
    return _makePutRequestWithHeader(url, data);
}

export const pauseLoyaltyTier = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/pause_tier`;
    return _makeGetRequestWithHeader(url, data);
};

export const startLoyaltyTier = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/start_tier`;
    return _makeGetRequestWithHeader(url, data);
};

export const deleteLoyaltyTier = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/delete_tier`;
    return _makeGetRequestWithHeader(url, data);
};

export const pauseLoyaltyRule = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/pause_rule`;
    return _makeGetRequestWithHeader(url, data);
};
export const startLoyaltyRule = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/start_rule`;
    return _makeGetRequestWithHeader(url, data);
};
export const deleteLoyaltyRule = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/delete_rule`;
    return _makeGetRequestWithHeader(url, data);
};



export const pauseGiftingRule = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/manual_trigger/pause_trigger_config`;
    return _makeGetRequestWithHeader(url, data);
};



export const playGiftingRule = (data) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/manual_trigger/play_trigger_config`;
    return _makeGetRequestWithHeader(url, data);
};

export const deleteGiftingRule = (data) => {
    let url;
    url = `${ServerUrl.XENOSERVER}api/xeno/pui/manual_trigger/delete_trigger_config`;
    return _makeGetRequestWithHeader(url, data);
};

export const getDataCleaning = (id, data = null, type) => {
    const url = {
        Department: `api/xeno/product/getProductDepartmentForMerchant/`,
        Category: `api/xeno/product/getProductCategoryForMerchant/`,
        SubCategory: `api/xeno/product/getProductSubcategoryForMerchant/`,
        Attribute: `api/xeno/product/getProductAttributesForMerchant/`,
    };
    return _makeGetRequestWithHeader(
        `${ServerUrl.CDP_PUI_URL}${url[type]}${id}`,
        data
    );
};

export const getProductHeirachy = (id, data = null) => {
    const url = `api/xeno/product/getProductHeirachy?merchant_id=${id}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const updateDataCleaning = (data) => {
    const url = `api/xeno/product/productCleaner`;

    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getVendorIntegrations = (data) => {
    const url = "api/xeno/merchant/getVendorIntegrations";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getXenoPreferredVendorIntegrations = (data) => {
    const url = "api/xeno/merchant/getXenoPreferredVendorIntegrations";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data)
}

export const disableVendorIntegration = (data) => {
    const url = "api/xeno/merchant/disableVendorIntegration";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
}

export const uploadVendorIntegrationsData = (
    postData = null,
    vendorId,
    merchantId
) => {
    const url = `api/xeno/merchant/saveVendorConf?merchant_id=${merchantId}&vendor_id=${vendorId}`;
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getCustomerView = (data = null) => {
    const url = `api/xeno/pui/loyalty/customer_view`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getPointsHistory = (data = null) => {
    const url = `api/xeno/pui/loyalty/points_history`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getRedemptionHistory = (data = null) => {
    const url = `api/xeno/pui/loyalty/get_redemption`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getTierHistory = (data = null) => {
    const url = `api/xeno/pui/loyalty_setup/get_tier_history`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const saveUploadConfig = (data = null) => {
    const url = "api/xeno/uploadconfig/saveConfig";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const addNewUpload = (data = null) => {
    const url = "api/xeno/uploads/addNewUpload";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const processUploads = (data) => {
    const url = `api/xeno/uploads/processUpload`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const uploadCSVToServer = (postData) => {
    const url = "api/xeno/uploads/uploadFileToS3?folder_name=uploads";
    return fetch(`${ServerUrl.XENOSERVER}${url}`, {
        method: "POST",
        body: postData,
    });
};

export const getAllUploads = (merchantId = null) => {
    const url = `/api/xeno/uploads/getAllUploads?merchant_id=${merchantId}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`);
};

export const downloadCSV = (url) => {
    return _makeGetRequestWithHeader(url);
};

export const getJourneyTypeList = (data = null) => {
    const url = "api/xeno/pui/journey/getCreationData";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const editRunningJourney = (data = null) => {
    const url = "api/xeno/pui/journey/edit";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const saveJourneyData = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = `api/xeno/pui/journey/save` + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getJourneyVariationsAudienceCount = (data) => {
    const url = "api/xeno/pui/journey/getPersonalizedCustomerCount";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getJourneyContentData = (data) => {
    const url = "api/xeno/pui/content/get";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const saveJourneyContentData = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = `api/xeno/pui/content/save` + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getJourneyListing = (data = null) => {
    const url = "api/xeno/pui/journey/getByMerchant";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getTriggerConfigList = (data = null) => {
    const url = "api/xeno/pui/manual_trigger/trigger_config_list";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getDropdowndataGifting = (data = null) => {
    const url = "api/xeno/pui/manual_trigger/active_triggers";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const saveTriggerConfig = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = `api/xeno/pui/manual_trigger/save_trigger_config` + queryParam;
    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getEditGifting = (data = null) => {
    const url = "api/xeno/pui/manual_trigger/trigger_config";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const updateSegmentRules = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/filters/saveFilter" + queryParam;
    return _makePostRequest(`${ServerUrl.CDP_PUI_URL}${url}`, postData);
};

export const getValidateSegment = (getData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url   = "api/xeno/filters/validateFilter" + queryParam;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`);
}

export const getSegmentDynamicDropdownOption = (endUrl = null, data = null) => {
    if (!isEmpty(endUrl)) {
        const url = "api/xeno/" + endUrl;
        return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
    }
};

export const getSegmentRuleCustomerCount = (postData) => {
    const url =
        "api/xeno/merchant/getCustomersFilteredCount/" +
        merchant().id +
        "?optout_customers=yes&is_new_tool=true";
    return _makePostRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, postData);
};

export const getSegmentationJsonConfig = () => {
    const url = "https://api.npoint.io/c27e64fd04817659332a";
    return _makeGetRequest(`${url}`, null);
};

export const getSegmentIdData = (data = null) => {
    const url = "api/xeno/filters/editFilter";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};
export const getAllSegmentsData = (data = null) => {
    const url = "api/xeno/filters/getAllFilters";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};
export const getFbSync = (data = null) => {
    const url = "api/xeno/filters/customAudianceSync";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const verifyUserEmail = (data = null) => {
    const url = "api/xeno/users/getUser";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const generateOTP = (data = null) => {
    const url = "api/xeno/pui/otp/generateOTP";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const verifyOTP = (postData) => {
    const url = "api/xeno/login/loginWithOtp";
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getUserMerchantMapList = (data = null) => {
    const url = "api/xeno/users/getAllPrograms";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getTokenDetails = (data = null) => {
    const url = "api/xeno/users/getTokenDetails";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const updatePassword = (userId, data = null) => {
    try {
        const url = `api/xeno/users/updatePasswordById/${userId}`;
        return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
    } catch (err) {
    }
};

export const updatePasswordUsingOld = (data = null) => {
    const url = "api/xeno/users/updatePasswordUsingOld";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const addReportExport = (data = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(data)) {
        queryParam += `?${_convertObjectToQueryParam(data)}`;
    }
    const url = "api/xeno/merchant/addReportExport" + queryParam;
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getExportCampaignList = (data = null) => {
    const url = "api/xeno/master_reports/getMasterCampaignList";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const demoReferalCode = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/customers/add" + queryParam;
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getSegmentData = (data = null) => {
    const url = "api/xeno/filters/getFilterDetails";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getCustomerList = (data = null) => {
    const url = "api/xeno/filters/getFilterMemberDetails";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const updateSegmentFiltersById = (data = null) => {
    const url = "api/xeno/filters/updateFilterById";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const deleteSegment = (data = null) => {
    const url = "api/xeno/filters/deleteFilter";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const sendTestEmailCampaign = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/emailcampaigns/sendTestMail" + queryParam;
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const searchSegmentByName = (getData) => {
    const url = "api/xeno/filters/searchFilter";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};

export const getSmsPreviewData = (data) => {
    const url = "api/xeno/pui/campaignStaging/getSMSPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getWhatsappPreviewData = (data) => {
    const url = "api/xeno/pui/journey/getWhatsAppPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getAppNotificationPreviewData = (data) => {
    const url = "api/xeno/pui/content/app_notif_preview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getAppNotifPreviewData = (data) => {
    const url = "api/xeno/pui/campaignStaging/app_notif_preview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};
export const getEmailPreviewData = (data) => {
    const url = "api/xeno/pui/campaignStaging/getEmailPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};
export const getUseCaseSplitesList = (data) => {
    const url = "api/xeno/usecase/getUseCaseSplits";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const totalSplitCount = (data) => {
    const url = `api/xeno/merchant/getCustomersCount/${data}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`);
};

export const editSplitCount = (data) => {
    const url = "splitAllCustomers";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const splitNewCustomers = (data) => {
    const url = "splitNewCustomers";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getUseCasesList = (data) => {
    const url = "api/xeno/usecase/getUseCases";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const updateUseCases = (data) => {
    const url = "updateUseCases";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getConfigurationUsecase = (data) => {
    const url = "merchantconfig/getMerchantConfig";
    return _makeGetRequest(`${ServerUrl.CDPURL}${url}`, data);
};

export const editDormantDays = (data) => {
    const url = "merchantconfig/updateDormantDays";
    return _makeGetRequest(`${ServerUrl.CDPURL}${url}`, data);
};

export const editManualUsecaseSegments = (data) => {
    const url = "merchantconfig/updateUseCaseManualSegments";
    return _makeGetRequest(`${ServerUrl.CDPURL}${url}`, data);
};

export const getAllFilterManualSegment = (data) => {
    const url = "api/xeno/filters/getAllFilters";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getCustomerFeedback = (data) => {
    const url = "api/xeno/feedback/getfeedbackforcustomer";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getMerchantUploadConfig = (data) => {
    const url = "api/xeno/uploadconfig/getConfig";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};
export const getCampaignAnalyticsData = (data) => {
    const url = "getCampaignAnalytics";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const saveAdAccessTokenFacebook = (data) => {
    const url = "api/xeno/merchant/saveAdAccessToken";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const facebookAccountIdSave = (data) => {
    const url = "api/xeno/merchant/saveAccountId";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const facebookPageIdSave = (merchantId, data) => {
    const url = `api/xeno/merchant/addFacebookUrl/${merchantId}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const instagramSaveAccount = (postData = null) => {
    const url = "api/xeno/merchant/saveMerchantFBConfig";

    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};
export const facebookDisconnect = (merchantId, data) => {
    const url = `api/xeno/merchant/disconnectFacebookForMerchant/${merchantId}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getDropDownDataInstagramAccount = (data) => {
    const url = "api/xeno/pui/fb/getInstagramAccounts";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getDropDownDataPixel = (data) => {
    const url = "api/xeno/pui/masterCampaign/getPixelInfo";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getDropDownDataCountryCode = (data) => {
    const url = "api/xeno/pui/masterCampaign/getCountryList";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getDropDownDataCurrency = (data) => {
    const url = "api/xeno/pui/masterCampaign/getCurrency";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getPermanentAccessToken = (data) => {
    const url = "https://graph.facebook.com/v18.0/oauth/access_token";
    return _makeGetRequest(url, data);
};

export const startFileUploadProcess = (data) => {
    const url = "startUploadAsync";
    return _makeGetRequest(`${ServerUrl.XENONODEURL}${url}`, data);
};

export const uploadCSVFileToGCP = (getData, postData) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = `${ServerUrl.XENONODEURL}uploadFile` + queryParam;
    return fetch(url, {
        method: "POST",
        body: postData,
    });
};

export const getMerchantListByUser = (data) => {
    let url = "api/xeno/login/getMerchantForUser";
    return _makeGetRequest(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getMerchantDataById = (data) => {
    let url = "api/xeno/merchant/getMerchantFromId";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};

export const getPostIntegrationData = (data) => {
    let url = "api/xeno/IntegrationSetup/get_all_integrations_for_merchant";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};
//

export const editPosistIntegration = (data) => {
    let url = "api/xeno/IntegrationSetup/posist_edit_integration";
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getLocationDropDownData = (data) => {
    let url =
        "api/xeno/IntegrationSetup/get_active_locations_with_no_integration";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const addNewLocationPosist = (data) => {
    let url = "api/xeno/IntegrationSetup/save_new_integration";
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const changeScheduledFrequency = (data) => {
    let url = "api/xeno/IntegrationSetup/change_schedule_freq";
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const changeStatusPosistIntegration = (data) => {
    let url = "api/xeno/IntegrationSetup/posist_change_status_of_integration";
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const searchPosistIntegrationByLocationName = (data) => {
    let url = "api/xeno/IntegrationSetup/posist_search_location";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const isMagentoIntegrationData = (data) => {
    let url = "api/xeno/IntegrationSetup/magento_get_active_integration";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const saveMagentoIntegrationfun = (data) => {
    let url = "api/xeno/IntegrationSetup/save_new_integration";
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getInactiveMagentoData = (data) => {
    let url = "api/xeno/IntegrationSetup/magento_get_inactive_integration";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getAllReportsAvailable = (data) => {
    let url = "api/xeno/reports/getAllReportsAvailable";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getAllScheduledReports = (data) => {
    let url = "api/xeno/reports/getAllScheduledReports";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getAllReportsLog = (data) => {
    let url = "api/xeno/reports/getAllReportsLog";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const addReportDownload = (getData, postData) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    let url = "api/xeno/reports/addReportDownload" + queryParam;
    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const getAllSegmentBuisnessReport = (data) => {
    let url = "api/xeno/filters/getFilters";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const downloadAutomatedBuisnessReports = (getData, postData) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/reports/scheduleReport" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const sendSMSTestCampaign = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/campaignStaging/run_test_campaign" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const sendTestSMSCampaignNew = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/campaignStaging/send-test-sms" + queryParam;

    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

//Send Test Combined APi
export const sendTestNew = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/campaignStaging/send-test" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, postData);
};

export const sendWhatsappTestCampaign = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
        const url =
            "api/xeno/pui/campaignStaging/run_test_whatsapp_campaign" + queryParam;
        return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
    }
};
export const sendWhatsAppTestJourney = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/journey/sendTestWhatsApp" + queryParam;

    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};
export const sendSMSTestJourney = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/journey/sendTestSMS" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const resendSMSTestCampaign = (getData) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/campaignStaging/resend_test_campaign" + queryParam;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`);
};
export const resendJourneyTest = (getData) => {
    const url = "api/xeno/pui/content/resendTestJourney";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};
export const getDataHitRate = (data, getData) => {
    const url = `api/xeno/product/getCleanData/${data}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const getDataProductHitRate = (data) => {
    const url = `api/xeno/product/getAllProducts/${data}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`);
};

export const saveDefineGoalHitRate = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/masterCampaign/updateCampaignGoal" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const deleteScheduledReport = (getData) => {
    const url = "api/xeno/reports/deleteScheduledReport";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const getDropDownSyncAudience = (getData) => {
    const url = "api/xeno/merchant/getMailchimpAudiences";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const saveMailChimpAudience = (getData) => {
    const url = "api/xeno/merchant/saveMailchimpAudience";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const mailChimpAudienceInfo = (getData) => {
    const url = "api/xeno/merchant/getMailchimpAudienceInfo";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const mailChimpDisconnect = (getData) => {
    const url = "api/xeno/merchant/disconnectVendor";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const updateCampaignFbData = (getData) => {
    const url = "api/xeno/masterCampaign/update_fb_data";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const getJourneyDataById = (data) => {
    const url = "api/xeno/pui/journey/get";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const pauseJourneyActionTrigger = (data) => {
    const url = "api/xeno/pui/journey/pauseTrigger";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const playJourneyActionTrigger = (data) => {
    const url = "api/xeno/pui/journey/resumeTrigger";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const deleteJourneyActionTrigger = (data) => {
    const url = "api/xeno/pui/journey/deleteTrigger";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const pauseJourney = (data) => {
    const url = "api/xeno/pui/journey/pause";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const playJourney = (data) => {
    const url = "api/xeno/pui/journey/resume";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const deleteJourney = (data) => {
    const url = "api/xeno/pui/journey/delete";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const duplicateJourney = (data) => {
    const url = "api/xeno/pui/journey/duplicate";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getJourneySmsPreviewData = (data) => {
    const url = "api/xeno/pui/journey/getSMSPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getJourneyEmailPreviewData = (data) => {
    const url = "api/xeno/pui/journey/getEmailPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getSmsChannelWisePerformaceData = (data) => {
    const url = "getSMSListing";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getFbChannelWisePerformanceData = (data) => {
    const url = "getFBListing";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getEmailChannelWisePerformanceData = (data) => {
    const url = "getEmailListing";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};
export const getNumericStatsReturnRevenue = (data) => {
    const url = "getNumericStats";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getCompleteCampaignOverviewData = (data) => {
    const url = "getCampaignOverview";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getCompleteJourneyOverviewData = (data) => {
    const url = "getJourneyOverview";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const submitJourneyData = (data) => {
    const url = "api/xeno/pui/journey/submit";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getIncrementalRevenueGraphData = (data) => {
    const url = "getIncrementalRevenueGraphData";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getResponderRevenueGraphData = (data) => {
    const url = "getResponderRevenueGraphData";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getCampaignSourceConversionsData = (data) => {
    const url = "getCampaignSourceConversions";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const statusUpdateCampaignDashboard = (data) => {
    const url = "updateCampaignStatsForMerchant";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const sendTestEmailJourney = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/content/sendTestEmailJourney" + queryParam;
    return _makePostRequest(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const sendTestPersonalizedEmailCampaign = (
    getData = null,
    postData = null
) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/campaignStaging/run_test_email_campaign" + queryParam;
    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, postData);
};

export const updateAttributionDay = (data) => {
    const url = "api/xeno/pui/campaignStaging/updateAttributionDays";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getUseCasePerformance = (data) => {
    const url = "getUsecaseStats";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getHitRatePerformance = (data) => {
    const url = "getHitRateStats";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};
export const getRepeatContribution = (data) => {
    const url = "getRepeatContribution";
    return _makeGetRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, data);
};

export const getAllBusinesses = (data) => {
    const url = "api/xeno/users/getAllBusinesses";
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, data);
};
// todo - change this to non hard coded
export const authenticateClaimLoyaltyPoints = (data) => {
    const url = "api/xeno/loyalty_setup/authenticateClaimLoyaltyPoints";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const claimLoyaltyPointsWithOtp = (data) => {
    const url = "api/xeno/ginesys/v1/claimLoyaltyPointsWithOtp";
    return _makePostRequest(
        `${ServerUrl.XENOSERVER}${url}?${_convertObjectToQueryParam(data)}`
    );
};

export const claimCoupon = (data) => {
    const url = "api/xeno/ginesys/v1/claimCoupon";
    return _makePostRequest(`${url}?${_convertObjectToQueryParam(data)}`);
};

export const getWhatsappDataFromTemplateId = (data) => {
    const url = "api/xeno/whatsapp/get_approved_whatsapp_template_data";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getCampiagnWhatsappPreviewData = (data) => {
    const url = "api/xeno/pui/campaignStaging/getWhatsAppPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getJourneyWhatsappPreviewData = (data) => {
    const url = "api/xeno/pui/journey/getWhatsAppPreview";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getActiveChannelName = (data) => {
    const url = "api/xeno/pui/content/channel_data";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};
export const getBusinessDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getBusinessDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getCustomerDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getCustomerDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getOrderDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getOrderDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getLoyaltyDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getLoyaltyDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getOtpDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getOtpDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getCampaignDashboardV2EmbedUrl = (data) => {
    const url = "api/xeno/analytics/getCampaignDashboardV2EmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getMyDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getLalsDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getCampaignHitRateDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getShoppersStopDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getfabIndiaDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getFabIndiaDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getBeingHumanCouponRedemptionDashboardEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getBeingHumanCouponRedemptionDashboardEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getSocialFraudDetectionQuicksightEmbedUrl = (data) => {
    const url = "api/xeno/analytics/getSocialFraudDetectionQuicksightEmbedUrl";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getManagementKPIFabIndia = (data) => {
    const url = "api/xeno/analytics/getManagementKPIFabIndia";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getAnalyticsReportKPIFabIndia = (data) => {
    const url = "api/xeno/analytics/getAnalyticsReportKPIFabIndia";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getPointsExpiryFabIndia = (data) => {
    const url = "api/xeno/analytics/getPointsExpiryFabIndia";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const uploadWhatsAppImageToServer = ({ file, folderName, type }) => {
    var data = new FormData();
    data.append("file", file);
    const url = "api/xeno/uploads/uploadWhatsAppMultimediaToS3";
    // * hardcoded as this api works in ai backend
    return fetch(
        `${ServerUrl.XENOSERVER}${url}?folder_name=${folderName}&type=${type}`,
        {
            method: "POST",
            body: data,
        }
    );
};

export const sendTestReSyncApi = (data) => {
    const url = "api/xeno/pui/content/get_dlr_by_campaignid";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const sendTestReSyncApiOTP = (data) => {
    const url = "api/xeno/pui/otp/v2/getDlrForTestOtp";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getFacebookConf = (data) => {
    const url = "api/xeno/merchant/getFacebookConfig";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const sendTestReSyncJourney = (data) => {
    const url = "api/xeno/pui/journey/getDeliveryStatsJourneys";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};
export const getAdAccountInfo = (data) => {
    const url = "api/xeno/pui/fb/getAdAccountInfo";
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getFbSenderData = (data) => {
    const url = "api/xeno/fb/getFbSenderData";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
}

export const getFbSpecificValidations = (data) => {
    const url = "api/xeno/fb/getFbSpecificValidations";
    return _makeGetRequest(`${ServerUrl.XENOSERVER}${url}`, data);
}


export const sendTestWhatsAppOTP = (getData, postData) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/otp/v2/sendTestOtp" + queryParam;

    return _makePostRequestWithHeader(
        `${ServerUrl.XENOSERVER}${url}`,

        postData
    );
};
export const resendTestWhatsAppOtp = (getData, data) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = "api/xeno/pui/otp/v2/resendSendTestOtp" + queryParam;

    return _makePostRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, data);
};

export const getAllChannel = (getData = null) => {
    const url = "api/xeno/merchant/getAllMerchantChannels";

    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};

export const getAllLocation = (getData = null, merchant) => {
    const url = `api/xeno/location/getLocationsForMerchant/${merchant}`;

    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};

export const getAllproductCategory = (getData = null, merchant) => {
    const url = `api/xeno/product/getCleanData/${merchant}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};

export const getAllproducts = (getData = null, merchant) => {
    const url = `api/xeno/product/getProductsForMerchant/${merchant}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};

export const getAllProductsWithExternalId = (getData = null, merchant) => {
    const url = `api/xeno/product/getAllProducts/${merchant}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};

export const getProductAttribute = (getData = null, merchant) => {
    const url = `api/xeno/product/getProductAttributesForMerchant/${merchant}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};
export const getLineItemAttribute = (getData = null, merchant) => {
    const url = `api/xeno/product/getLineItemAttributesForMerchant/${merchant}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};
export const getProductAttributeMap = (getData = null, merchant) => {
    const url = `api/xeno/product/getProductAttributesValueMapForMerchantAttribute/${merchant}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CDP_PUI_URL}${url}`, getData);
};
export const saveCapaignGoal = (getData = null, postData = null) => {
    let queryParam = "";
    if (isDataSet(getData)) {
        queryParam += `?${_convertObjectToQueryParam(getData)}`;
    }
    const url = `campaign_goals` + queryParam;
    return _makePutRequestWithHeader(`${ServerUrl.FLASKSERVER}${url}`, postData);
};
export const getCapaignGoal = (getData = null) => {
    const url = `campaign_goals`;
    return _makeGetRequest(`${ServerUrl.FLASKSERVER}${url}`, getData);
};

export const getProductAttributeType = (postData) => {
    const url = "campaign_goals/product_attribute_type";
    return _makePostRequest(`${ServerUrl.FLASKSERVER}${url}`, postData);
};

export const createOffer = (postData) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer`;
    return _makeRequestWithHeader(url, "POST", postData, false, "ai.xeno", merchant().id); // todo - configure source using better logic
};

export const editOffer = (offerId, putData) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer/${offerId}`;
    return _makeRequestWithHeader(url, "PUT", putData, false, "ai.xeno", merchant().id);
};

export const getAllOffers = (
    page = 0,
    pageSize = 10,
    searchKey = "",
    sortDirection = "DESC",
    sortBy = "",
    status = "",
    couponStatisticsRequired = true,
    showOnlyLoyaltyLinked = false,
) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer?pageNo=${page}&pageSize=${pageSize}&searchKey=${searchKey}&status=${status}&sortDirection=${sortDirection}&sortBy=${sortBy}&couponStatisticsRequired=${couponStatisticsRequired}&showOnlyLoyaltyLinked=${showOnlyLoyaltyLinked}`;
    return _makeRequestWithHeader(url, "GET", null, false, "ai.xeno", merchant().id);
};

export const getOfferUploadsExportsHistory = (
    page = 0,
    pageSize = 10,
    searchKey = "",
    sortDirection = "DESC",
    sortBy = "",
    status = "",
) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/upload/history?pageNo=${page}&pageSize=${pageSize}&searchKey=${searchKey}&status=${status}&sortDirection=${sortDirection}&sortBy=${sortBy}`;
    return _makeRequestWithHeader(url, "GET", null, false, "ai.xeno", merchant().id);
};

export const getOfferDetails = (offerId) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer/${offerId}`;
    return _makeRequestWithHeader(url, "GET", null, false, "ai.xeno", merchant().id);
};

export const exportOfferCoupons = (postData) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/coupon/export`;
    return _makeRequestWithHeader(url, "POST", postData, false, "ai.xeno", merchant().id);
};

export const reuploadOfferCoupons = (postData) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/upload/coupons`;
    return _makeRequestWithHeader(url, "POST", postData, false, "ai.xeno", merchant().id);
}

export const getPresignedUrlForFileUpload = (postData) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/upload/url`;
    return _makeRequestWithHeader(url, "POST", postData, false, "ai.xeno", merchant().id);
};
export const getDownloadUrlforUploadedCouponFile = (offerId) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/upload/url?id=${offerId}`;
    return _makeRequestWithHeader(url, "GET", null, false, "ai.xeno", merchant().id);
};

export const getPresignedUrlForFileUploadXeno = (postData) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/uploads/url`;
    return _makeRequestWithHeader(url, "POST", postData, true, null);
};

export const deleteOffer = (offerId) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer/${offerId}`;
    return _makeRequestWithHeader(url, "DELETE", null, false, "ai.xeno", merchant().id);
};

export const getMerchantOMConfig = (tokenID) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/merchant/getMerchantDetailsByApiKey/${tokenID}`;
    return _makeGetRequestWithHeader(url);
};

export const getDistributionOffers = (merchantId, distributionMedium = "CAMPAIGN") => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer/distribution?merchantId=${merchantId}&distributionMedium=${distributionMedium}`;
    return _makeRequestWithHeader(url, "GET", null, false, "ai.xeno", merchant().id);
}

export const getLoyaltyOfferActions = (merchantId) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty_setup/get_rule_actions_with_offers?merchantId=${merchantId}`;
    return _makeGetRequestWithHeader(url);
}

export const deactivateOffer = (offerId) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer/deactivate/${offerId}`;
    return _makeRequestWithHeader(url, "PUT", null, false, "ai.xeno", merchant().id);
};

export const activateOffer = (offerId) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer/activate/${offerId}`;
    return _makeRequestWithHeader(url, "PUT", null, false, "ai.xeno", merchant().id);
};

export const getLocationData = (data) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/location/list?apiKey=${data.apiKey}&pageNo=${data.page}&pageSize=${data.pageSize}&searchKey=${data.searchKey}&externalIds=${data.externalIds}`;
    return _makeRequestWithHeader(url, "GET", null, false, null);
};

export const getChannelData = (data) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/channel/list?apiKey=${data.apiKey}&pageNo=${data.page}&pageSize=${data.pageSize}&searchKey=${data.searchKey}`;
    return _makeRequestWithHeader(url, "GET", null, false, null);
};

export const getProductDepartmentData = (data) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/product/department/list?apiKey=${data.apiKey}&pageNo=${data.page}&pageSize=${data.pageSize}&searchKey=${data.searchKey}&externalIds=${data.externalIds}`;
    return _makeRequestWithHeader(url, "GET", null, false, null);
};

export const getProductData = (data) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/product/sku/list?apiKey=${data.apiKey}&pageNo=${data.page}&pageSize=${data.pageSize}&searchKey=${data.searchKey}&externalIds=${data.externalIds}`;
    return _makeRequestWithHeader(url, "GET", null, false, null);
};

export const getProductCategoryData = (data) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/product/category/list?apiKey=${data.apiKey}&pageNo=${data.page}&pageSize=${data.pageSize}&searchKey=${data.searchKey}&externalIds=${data.externalIds}`;
    return _makeRequestWithHeader(url, "GET", null, false, null);
};

export const getProductSubCategoryData = (data) => {
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/product/subcategory/list?apiKey=${data.apiKey}&pageNo=${data.page}&pageSize=${data.pageSize}&searchKey=${data.searchKey}&externalIds=${data.externalIds}`;
    return _makeRequestWithHeader(url, "GET", null, false, null);
};

export const getActionsHistory = (merchantId, actionType, pageNo, pageSize) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty/utility/actionHistory?merchantId=${merchantId}&actionType=${actionType}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return _makeGetRequestWithHeader(url);
};

export const modifyPointsUsingLoyaltyUtility = (data, merchantId) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty/utility/modifyPoints?merchantId=${merchantId}`;
    return _makePostRequestWithHeader(url, data);
};

export const getCustomerDetailByPhone = (merchant_id, phone) => {
    // phone has to be without country code
    const url = `${ServerUrl.XENOSERVER}api/xeno/customers/getCustomerDetailByPhone?merchant_id=${merchant_id}&phone=${phone}`;
    return _makeRequestWithHeader(url, "GET", null, true, null);
};

export const getCustomerDetailById = (customerId, merchantId) => {
    // phone has to be without country code
    const url = `${ServerUrl.CDP_PUI_URL}api/xeno/customers/getCustomerDetail?customer_id=${customerId}&merchant_id=${merchantId}`;
    return _makeRequestWithHeader(url, "GET", null, true, null);
};

export const getCustomerLoyaltyPointsByPhone = (merchantId, phone) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty/getLoyaltyPoints?merchantId=${merchantId}&customerPhone=${phone}&storeKey=123`;
    return _makeGetRequestWithHeader(url);
};

export const getTextFromAudio = (data = null, signal) => {
    const url = `${ServerUrl.GENAI_URL}api/v1/voice-to-text`;
    // explicity declared here instead of using _makePostRequest as this api requires a different header
    return fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${user().tokenId}`
        },
        body: data,
        signal
    });
}

export const getSqlQuery = (data = null, signal) => {
    const url = `${ServerUrl.GENAI_URL}api/v1/predict`;
    return _makePostRequestWithHeaderAndSignal(url, data, undefined, undefined, signal);
}

export const getCsvResultFromSqlQuery = (data = null, signal) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/gen-ai-rpt/resolve-query`;
    return _makePostRequestWithHeaderAndSignal(url, data, undefined, undefined, signal);
}

export const submitFeedback = (data = null) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/gen-ai-rpt/capture-feedback`;
    return _makePostRequestWithHeader(url, data);
}
export const getSendtestVariables = (data = null) => {
    const url = "api/xeno/pui/campaignStaging/get_sendtest_variables";
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`, data);
};

export const getMerchantViewPermissions = (param) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/merchant/getMerchantDetailsByApiKey/${param}`

    return _makeGetRequestWithHeader(url)
}

export const getTemplateSynced = (formData = null, merchantId = null, vendorId = null) => {
    const url = `api/xeno/pui/whatsapp/syncCsvWhatsAppTemplate?merchantId=${merchantId}&vendorId=${vendorId}`;
    return _makePostRequestWithHeaderFormData(`${ServerUrl.XENOSERVER}${url}`, formData);
};

export const getConfiguredWhatsappVendors = (merchantId = null) => {
    const url = `api/xeno/pui/whatsapp/getConfiguredWhatsappVendors?merchantId=${merchantId}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`);
}

export const getWhatsappMarketingVendorExtras = (merchantId = null) => {
    const url = `api/xeno/pui/whatsapp/getWhatsappMarketingVendorExtras?merchantId=${merchantId}`;
    return _makeGetRequestWithHeader(`${ServerUrl.CAMPAIGNSERVER}${url}`);
}

export const dashboardUrl = () => {
    const merch = merchant();
    if (isDataSet(merch) && merchant().mydashboardUrl) {
        return merchant().mydashboardUrl;
    }
    else {
        return "api/xeno/analytics/getKamaDashboardEmbedUrl";
    }

};

export const getDashboardConfig = (getData = null, merchantId) => {
    const url = `api/xeno/merchant/getDashboardConfig/${merchantId}`;
    return _makeGetRequestWithHeader(`${ServerUrl.XENOSERVER}${url}`, getData);
};

export const getLoyaltyTiers = (data) => {
    let url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty/getloyaltytiers`;
    return _makeGetRequestWithHeader(url, data);
};

export const modifyTiersUsingLoyaltyUtility = (data, merchantId) => {
    const url = `${ServerUrl.XENOSERVER}api/xeno/pui/loyalty/utility/modifyTier?merchantId=${merchantId}`;
    return _makePostRequestWithHeader(url, data);
};

export const getAllReward = (
    page = 0,
    pageSize = 10,
    searchKey = "",
    sortDirection = "DESC",
    sortBy = "",
    status = "ACTIVE",
    couponStatisticsRequired = false,
    showOnlyLoyaltyLinked = false,
) => {
    const url = `${ServerUrl.OFFER_MANAGEMENT_URL}pui/api/v1/offer?pageNo=${page}&pageSize=${pageSize}&searchKey=${searchKey}&status=${status}&sortDirection=${sortDirection}&sortBy=${sortBy}&couponStatisticsRequired=${couponStatisticsRequired}&showOnlyLoyaltyLinked=${showOnlyLoyaltyLinked}`;
    return _makeRequestWithHeader(url, "GET", null, false, "ai.xeno", merchant().id);
};
