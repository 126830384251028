import React from "react";
import Loader from "react-loaders";
import { isEmpty } from "../../utils/functions/generalUtils";
import isDataSet from "../../utils/functions/isDataSet";

function PageLoader({
  type = "square-spin",
  text = "Your data is loading...",
  height,
  style = null,
}) {
  let localStyle = {};
  if (isDataSet(height)) {
    localStyle = {
      height: height,
    };
  }

  if (isDataSet(style)) {
    localStyle = {
      ...localStyle,
      style,
    };
  }
  return (
    <div className="loader-container" style={localStyle}>
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type={type} />
        </div>
        {!isEmpty(text) ? <h6 className="mt-3">{text}</h6> : null}
      </div>
    </div>
  );
}

export default PageLoader;
