import { faAngleDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdatedPasswordModal from "Components/Loginmodals/UpdatedPasswordModal";
import UpdatePassword from "Components/Loginmodals/UpdatePassword";
import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";

import {
  Button,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledButtonDropdown,
} from "reactstrap";
import isDataSet from "../../utils/functions/isDataSet";
import userProfile from "../../assets/XMC-UI-1-ASSETS/user_profile.svg";
import { withRouter } from "react-router-dom";
function UserLoggedInView(props) {
  const { avatar, userName, merchant, merchantNames, merchantSel, Userlogout } =
    props;
  const [modalChangePassword, setChangePassword] = useState(false);
  const [passwordUpdatedModal, setPasswordUpdatedModal] = useState(false);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "2px blue",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "blue" : "white",
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "5%",
    }),
  };

  return (
    <Fragment>
      <UpdatePassword
        modalOpen={modalChangePassword}
        setModalOpen={setChangePassword}
        updatePasswordModal={passwordUpdatedModal}
        setupdatePasswordModal={setPasswordUpdatedModal}
      />
      <UpdatedPasswordModal
        modalOpen={passwordUpdatedModal}
        setModalOpen={setPasswordUpdatedModal}
      />
      <div className=" pr-4">
        <div className="widget-content p-0 text-white">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown id="profile-button">
                <DropdownToggle color="link" className="p-0">
                  <img
                    width={45}
                    className="rounded-circle"
                    src={userProfile}
                    alt=""
                  />

                  <FontAwesomeIcon
                    className="ml-2 opacity-8 text-white"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu
                  right
                  className="rm-pointers dropdown-menu-lg bg-info"
                >
                  <div className="dropdown-menu-header pb-0 mb-0">
                    <div className="dropdown-menu-header-inner ">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img
                                width={45}
                                height={45}
                                className="rounded-circle"
                                src={userProfile}
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">{userName}</div>
                              <div className="widget-subheading opacity-8">
                                {isDataSet(merchantSel) &&
                                  isDataSet(merchantSel.value) &&
                                  merchantSel.value.label}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button
                                className="btn-pill btn-shadow btn-shine"
                                color="focus"
                                onClick={Userlogout}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                          {merchantNames.length > 1 && (
                            <FormGroup className="pt-3">
                              {/*<Label for="exampleEmail">Basic</Label>*/}
                              <Select
                                id="nav-merchant-select"
                                value={merchantSel.value}
                                styles={customStyles}
                                isSearchable
                                onChange={merchantSel.onChange}
                                onFocus={merchantSel.onFocus}
                                options={merchantNames}
                                isClearable={true}
                                placeholder={"Search..."}
                              />
                            </FormGroup>
                          )}
                          <span
                            style={{
                              position: "relative",
                              left: "60%",
                              cursor: "pointer",
                            }}
                            onClick={() => setChangePassword(true)}
                          >
                            Change Password
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading">{userName}</div>
              <div className="widget-subheading">{merchant.name}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(UserLoggedInView);
