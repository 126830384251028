import isDataSet from "./isDataSet";

export function stringifyJson(json) {
  if (isDataSet(json)) {
    if (typeof json === "string" || json instanceof String) {
      return json;
    } else {
      return JSON.stringify(json);
    }
  }
  return "";
}
