import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updatePassword } from "Api";
import { getApiRequestData } from "Api/requestWrapper";
import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { isEmpty } from "utils/functions/generalUtils";
import { user } from "utils/functions/getStorageObject";
import isDataSet from "utils/functions/isDataSet";

const style = {
  width: "40%",
  maxWidth: "80rem",
};

const ChangePassword = ({
  modalOpen,
  setModalOpen,
  updatePasswordModal,
  setupdatePasswordModal,
}) => {
  const [visibleNew, setVisibleNew] = useState(false);
  const [visibleNewVerify, setVisibleNewVerify] = useState(false);
  const [error, setError] = useState(null);
  const password = useSetValue("");
  const verifyPassword = useSetValue("");
  const OpenModal = () => {
    sessionStorage.removeItem("modalOpenChangePassword");
    setupdatePasswordModal(true);
    const timer = setTimeout(() => {
      setupdatePasswordModal(false);
    }, 4000);
    return () => clearTimeout(timer);
  };
  const updatePasswordOtp = async () => {
    let errors = {};
    if (validPassword(password) && validPassword(verifyPassword)) {
      if (password.value.length < 5) {
        errors["length"] = "should contain atleast 5 digits";
      }
      if (password.value !== verifyPassword.value) {
        errors["equal"] = "password does not match";
      }
    }
    if (Object.keys(errors).length === 0) {
      let response = await getApiRequestData(updatePassword, user().userId, {
        user_id: user().userId,
        password: password.value,
      });

      if (response.status) {
        setModalOpen(false);
        OpenModal();
      } else {
        alert("error");
      }
    } else {
      setError(errors);
    }
  };

  const passwordIcon = (visible, setVisible) => (
    <FontAwesomeIcon
      icon={visible ? faEye : faEyeSlash}
      onClick={() => setVisible(!visible)}
    />
  );
  const toggleModal = () => {
    setModalOpen(false);
    sessionStorage.removeItem("modalOpenChangePassword");
  };

  const PasswordType = (visible) => (visible ? "text" : "password");
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} centered style={style}>
      <ModalHeader className="pl-4 pt-4 personlized-link " toggle={toggleModal}>
        <span style={{ fontWeight: "700" }}>ChangePassword</span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column align-items-center justify-content-center">
        <div>
          <Form style={{ marginTop: "40px", marginBottom: "40px" }}>
            <Row
              form
              style={{
                width: "500px",
                marginRight: "100px",
                marginLeft: "100px",
              }}
            >
              <Col sm={12} style={{ marginBottom: "20px" }}>
                <FormGroup
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Label
                      for="newPassword"
                      style={{ fontWeight: "600", fontSize: "17px" }}
                    >
                      New Password
                    </Label>
                    <Input
                      // className="search-input form-control"
                      type={PasswordType(visibleNew)}
                      id="newPassword"
                      // placeholder="vishad.tomar@xeno.in"
                      // mask={"+\\91 9999999999"}
                      // maskChar={null}
                      {...password}
                      style={{ height: "60px" }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "55px",
                        zIndex: "9999",
                        opacity: "40%",
                        fontSize: "16px",
                      }}
                    >
                      {passwordIcon(visibleNew, setVisibleNew)}
                    </span>
                  </div>
                </FormGroup>
                {isDataSet(error) && isDataSet(error["length"]) ? (
                  <p style={{ marginTop: "-15px", color: "#fe6565" }}>
                    {error["length"]}
                  </p>
                ) : null}
              </Col>
              <Col sm={12}>
                <FormGroup
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Label
                      for="examplePassword"
                      style={{ fontWeight: "600", fontSize: "17px" }}
                    >
                      Verify New Password
                    </Label>
                    <Input
                      type={PasswordType(visibleNewVerify)}
                      name="password"
                      //id="examplePassword"
                      //placeholder="Password here..."
                      {...verifyPassword}
                      style={{ height: "60px" }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "55px",
                        zIndex: "9999",
                        opacity: "40%",
                        fontSize: "16px",
                      }}
                    >
                      {passwordIcon(visibleNewVerify, setVisibleNewVerify)}
                    </span>
                  </div>
                </FormGroup>
                {isDataSet(error) && isDataSet(error["equal"]) ? (
                  <p
                    style={{
                      marginTop: "-15px",
                      color: "#fe6565",
                      marginBottom: "0px",
                    }}
                  >
                    {error["equal"]}
                  </p>
                ) : null}
              </Col>
            </Row>
          </Form>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <Button
            className="save-button-loyalty"
            onClick={() => updatePasswordOtp()}
            color="primary"
            style={{ width: "30%" }}
          >
            Save Password
          </Button>
          <a
            className="btn-lg btn btn-link"
            style={{
              fontSize: "15px",

              fontWeight: "200",
            }}
            onClick={() => toggleModal()}
          >
            I remember my password
          </a>{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};
function validPassword(password) {
  if (isDataSet(password) && !isEmpty(password.value)) {
    return true;
  }
  return false;
}
function useSetValue(initialValue) {
  const [value, setValue] = useState(initialValue);

  function onChange(event) {
    setValue(event.target.value);
  }

  return {
    value,
    onChange,
  };
}

export default ChangePassword;
