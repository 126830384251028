import isDataSet from "../utils/functions/isDataSet";

export async function getApiRequestData(apiFunction, ...args) {
  let data = {
    status: true,
    message: "success",
  };
  try {
    let resp = await apiFunction(...args);

    if (resp.ok) {
      resp = await resp.json();
      if (isDataSet(resp)) {
        data = {
          status: true,
          data: resp,
          message: "success",
        };
      } else {
        data = {
          status: false,
          message: "No data found",
        };
      }
    } else {
      data = {
        status: false,

        message: resp.message,
      };
    }
  } catch (error) {
    data = {
      status: false,

      message: error,
    };
  }

  return data;
}
export async function postApiRequestData(apiFunction, body) {
  let data = {
    status: false,
    message: "Something went wrong. Try again!",
  };
  try {
    let resp = await apiFunction(body);
    if (resp.ok) resp = await resp.json();
    if (resp.status) {
      data = {
        status: true,
        message: "success",
      };
    }
  } catch (error) {
    data = {
      status: false,
      message: error,
    };
  }
  return data;
}

export async function postApiRequestDataFormData(apiFunction, body, merchantId,vendorId) {
  let data = {
    status: false,
    message: "Something went wrong. Try again!",
  };
  try {
    let resp = await apiFunction(body, merchantId,vendorId);
    if (resp.ok) resp = await resp.json();
    if (resp.success) {
      data = {
        status: true,
        message: "success",
      };
    }else {
      //generate dummy messageList with two entries
      data = {
        status: false,
        messageList: resp.message,
      };
    }
  } catch (error) {
    data = {
      status: false,
      message: error,
    };
  }
  return data;
}
