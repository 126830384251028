import moment from "moment";
import isDataSet from "./isDataSet";

export const DATE_FORMATS = {
  DB_DATE_FORMAT: "YYYY-MM-DD HH:mm:SS",
  MONTH_DATE_YEAR: "MMM Do,YYYY",
  MONTH_DATE: "MMM Do",
  MONTH_DATE_HOUR_MIN_MERIDIAN: "MMM Do, h:mm a",
  DATE_MONTH_HOUR_MERIDIAN: " Do MMM, hh a ",
  MONTH_DATE_YEAR_HOUR_MIN_MERIDIAN: "MMM DD,YYYY h:mm A",
};

export const dbDateFormat = (dbDate, newFormat) => {
  return moment(dbDate, DATE_FORMATS.DB_DATE_FORMAT).format(newFormat);
};

export const validDate = (date) => {
  return isDataSet(date) && moment(date).isValid();
};

export const getGMTDateFromUTC = (date, dateFormat) => {
  return moment(date, dateFormat).add(5, "hours").add(30, "minutes");
};


export const getDbDateFromNow = (date) => {
  return moment(date, "YYYY ddd MMM DD HH:mm").fromNow();
};

export const getGMTFromIST=(timestamp)=>{
  let dateFromTimestamp=new Date(timestamp);
  let istToGmtDateAndTime=(new Date(dateFromTimestamp.getTime() - (5 * 60 + 30) * 60000)).toLocaleString();
  return istToGmtDateAndTime;
}